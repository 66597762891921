import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import PrimeVue from "primevue/config";
import Button from "primevue/button";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
// import MultiSelect from "primevue/multiselect";
import Listbox from "primevue/listbox";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Menubar from "primevue/menubar";
import Chart from "primevue/chart";
import Card from "primevue/card";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Dialog from "primevue/dialog";
import "nprogress/nprogress.css";

import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "primeflex/primeflex.css";
// import ProgressSpinner from "primevue/progressspinner";
import InputSwitch from "primevue/inputswitch";

const app = createApp(App);

app.use(store);
app.use(router);
app.use(PrimeVue);
app.use(ToastService);

app.component("Button", Button);
app.component("Toast", Toast);
app.component("Calendar", Calendar);
app.component("Dropdown", Dropdown);
// app.component("MultiSelect", MultiSelect);
app.component("Listbox", Listbox);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("Menubar", Menubar);
app.component("Chart", Chart);
app.component("Card", Card);
// app.component("ProgressSpinner", ProgressSpinner);
app.component("InputText", InputText);
app.component("Password", Password);
app.component("InputSwitch", InputSwitch);
app.component("Dialog", Dialog);

app.mount("#app");

//createApp(App).use(store).use(router).mount("#app");
