<template>
  <div class="home">
    <img
      src="../assets/images/m-golf-after-round.png"
      alt="m-golf-after-round"
    />
  </div>
  <div v-if="!loggedIn">
    <h1>To Enter / View Round Data:</h1>
    <h1>
      <router-link :to="{ name: 'Login' }">Login</router-link> or
      <router-link :to="{ name: 'Register' }">Register</router-link>
    </h1>
  </div>
</template>

<script>
import { computed } from "vue";
import store from "../store";

export default {
  name: "Home",
  setup() {
    const loggedIn = computed(() => {
      var x = store.getters["player/loggedIn"];
      console.log(`HOME loggedIn: ${x}`);
      return store.getters["player/loggedIn"];
    });
    return {
      loggedIn,
    };
  },
};
</script>
