<template>
  <Dialog v-model:visible="dialogDisplay">
    <template #header> Successful Login </template>
    <p>You may now login to enter / view data</p>
    <template #footer class="p-d-flex p-jc-center">
      <Button label="login" @click="dialogClose" />
      <Button label="quit" @click="resetAndHome" />
    </template>
  </Dialog>
  <div class="p-grid p-jc-center">
    <div class="p-col-12 p-md-6">
      <Card class="p-mt-4">
        <template #title> Register </template>
        <template #content>
          <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
            <div class="p-field">
              <!-- <Dropdown
                v-model="clubId"
                :options="clubs"
                optionValue="id"
                dataKey="id"
                optionLabel="club_name"
                placeholder="Identify your club"
              /> -->
              <Dropdown
                v-model="v$.clubId.$model"
                :options="clubs"
                optionValue="id"
                dataKey="id"
                optionLabel="club_name"
                placeholder="Identify your club"
                :class="{ 'p-invalid': v$.clubId.$invalid && submitted }"
              />
              <small
                v-if="
                  (v$.clubId.$invalid && submitted) ||
                  v$.clubId.$pending.$response
                "
                class="p-error"
                >{{
                  v$.clubId.required.$message.replace("Value", "club name")
                }}</small
              >
            </div>
            <div class="p-field">
              <div class="p-float-label">
                <InputText
                  id="firstName"
                  v-model="v$.firstName.$model"
                  :class="{ 'p-invalid': v$.firstName.$invalid && submitted }"
                />
                <label
                  for="firstName"
                  :class="{ 'p-error': v$.firstName.$invalid && submitted }"
                  >first name*</label
                >
              </div>
              <small
                v-if="
                  (v$.firstName.$invalid && submitted) ||
                  v$.firstName.$pending.$response
                "
                class="p-error"
                >{{
                  v$.firstName.required.$message.replace("Value", "first name")
                }}</small
              >
            </div>
            <div class="p-field">
              <div class="p-float-label">
                <InputText
                  id="lastName"
                  v-model="v$.lastName.$model"
                  :class="{ 'p-invalid': v$.lastName.$invalid && submitted }"
                />
                <label
                  for="lastName"
                  :class="{ 'p-error': v$.lastName.$invalid && submitted }"
                  >last name*</label
                >
              </div>
              <small
                v-if="
                  (v$.lastName.$invalid && submitted) ||
                  v$.lastName.$pending.$response
                "
                class="p-error"
                >{{
                  v$.lastName.required.$message.replace("Value", "last name")
                }}
              </small>
            </div>
            <div class="p-field">
              <div class="p-float-label">
                <InputText
                  id="userName"
                  v-model="v$.userName.$model"
                  :class="{ 'p-invalid': v$.userName.$invalid && submitted }"
                />
                <label
                  for="userName"
                  :class="{ 'p-error': v$.userName.$invalid && submitted }"
                  >user name*</label
                >
              </div>
              <small
                v-if="
                  (v$.userName.$invalid && submitted) ||
                  v$.userName.$pending.$response
                "
                class="p-error"
                >{{
                  v$.userName.required.$message.replace("Value", "user name")
                }}</small
              >
            </div>
            <div class="p-field">
              <div class="p-float-label p-input-icon-right">
                <i class="pi pi-envelope" />
                <InputText
                  id="email"
                  v-model="v$.email.$model"
                  :class="{ 'p-invalid': v$.email.$invalid && submitted }"
                />
                <label
                  for="email"
                  :class="{ 'p-error': v$.email.$invalid && submitted }"
                  >email*</label
                >
              </div>
              <span v-if="v$.email.$error && submitted">
                <span
                  id="email-error"
                  v-for="(error, index) of v$.email.$errors"
                  :key="index"
                  ><small class="p-error">{{ error.$message }}</small>
                </span>
              </span>
              <small
                v-else-if="
                  (v$.email.$invalid && submitted) ||
                  v$.email.$pending.$response
                "
                class="p-error"
                >{{
                  v$.email.required.$message.replace("Value", "email")
                }}</small
              >
            </div>
            <div class="p-field">
              <div class="p-float-label">
                <Password
                  id="password"
                  v-model="v$.password.$model"
                  :class="{ 'p-invalid': v$.password.$invalid && submitted }"
                  :feedback="false"
                  toggleMask
                />
                <label
                  for="password"
                  :class="{ 'p-error': v$.password.invalid && submitted }"
                  >password*</label
                >
              </div>
              <small
                v-if="
                  (v$.password.$invalid && submitted) ||
                  v$.password.$pending.$response
                "
                class="p-error"
                >{{
                  v$.password.required.$message.replace("Value", "password")
                }}</small
              >
            </div>
            <Button type="submit" label="Submit" class="p-mt-2 p-mb-4" />
            <router-link :to="{ name: 'Login' }">
              Have an account? Login.
            </router-link>
          </form>
          <div><Toast position="center" /></div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted, computed } from "vue";
import router from "../router";
import { required, email } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import store from "../store";

export default {
  name: "Profile",
  setup() {
    onMounted(() => {
      try {
        store.dispatch("club/getClubs");
      } catch (err) {
        console.log(`Error: Register.vue getClubs: ${err}`);
      }
    });
    const clubs = computed(() => {
      return store.state.club.clubs;
    });
    const dialogDisplay = ref(false);
    const submitted = ref(false);
    const state = reactive({
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      password: "",
      clubId: "",
    });
    const rules = {
      firstName: { required },
      lastName: { required },
      userName: { required },
      email: { required, email },
      password: { required },
      clubId: { required },
    };
    const v$ = useVuelidate(rules, state);
    function handleSubmit(isFormValid) {
      submitted.value = true;

      if (!isFormValid) {
        return;
      }
      postRegistration();
    }
    async function postRegistration() {
      // TO-DO:  GET CLUBS_ID
      try {
        const registrationData = {
          clubs_id: state.clubId,
          first_name: state.firstName,
          last_name: state.lastName,
          user_name: state.userName,
          email: state.email,
          password: state.password,
        };
        await store.dispatch("player/postRegistration", registrationData);
        // console.log(`REGISTER: store.state.player.player[0].token: ${store.state.player.player[0].token}`);
        dialogOpen();
        resetForm();
        // router.push({ name: "Home" });
      } catch (err) {
        console.log(`Error: Register.vue postRegistration: ${err}`);
      }
    }

    function resetForm() {
      state.clubId = "";
      state.firstName = "";
      state.lastName = "";
      state.userName = "";
      state.email = "";
      state.password = "";
      submitted.value = false;
    }

    function dialogClose() {
      dialogDisplay.value = false;
      router.push({ name: "Login" });
    }

    function dialogOpen() {
      dialogDisplay.value = true;
    }

    function resetAndHome() {
      resetForm();
      router.push({ name: "Home" });
    }

    return {
      clubs,
      state,
      v$,
      submitted,
      handleSubmit,
      resetForm,
      dialogDisplay,
      dialogClose,
      resetAndHome,
    };
  },
};
</script>

<style lang="scss" scoped>
.maxWidth {
  max-width: 500px;
}
.p-field {
  margin-bottom: 1.75rem;
}
</style>
