<template>
  <h1>Error</h1>
  <p><b>Location:</b></p>
  <p>{{ location }}</p>
  <p><b>Info From "Error":</b></p>
  <p>{{ err }}</p>
  <p>
    Go <a href="#" @click="$router.go(-1)"> <b>back</b></a> or
    <a href="#" @click="$router.push({ name: 'Home' })"><b>home</b></a>
  </p>
</template>

<script>
import router from "../router";
export default {
  name: "Error",
  props: {
    err: {
      type: String,
      required: false,
      default: "An error has occurred",
    },
    location: {
      type: String,
      required: false,
      default: "location not entered",
    },
  },
  setup() {
    function goHome() {
      router.push({ name: "Home" });
    }

    return {
      goHome,
    };
  },
};
</script>
