import EventService from "@/services/EventService.js";

export const namespaced = true;

export const state = {
  basicScores: [], // All roundData associated with a single round ID
  basicScoresIds: [],
  drivingStats: [],
  drivingStatsIds: [],
  approachStats: [],
  approachStatsIds: [],
  scramblingStats: [],
  scramblingStatsIds: [],
  puttingStats: [],
  puttingStatsIds: [],
};

export const mutations = {
  SET_BASIC_SCORES_DATA(state, basicScores) {
    state.basicScores = basicScores;
  },
  SET_BASIC_SCORES_IDS_DATA(state, basicScores) {
    state.basicScoresIds = basicScores;
  },
  SET_DRIVING_STATS(state, drivingStats) {
    state.drivingStats = drivingStats;
  },
  SET_DRIVING_STATS_IDS(state, drivingStats) {
    state.drivingStatsIds = drivingStats;
  },
  SET_APPROACH_STATS(state, approachStats) {
    state.approachStats = approachStats;
  },
  SET_APPROACH_STATS_IDS(state, approachStats) {
    state.approachStatsIds = approachStats;
  },
  SET_SCRAMBLING_STATS(state, scramblingStats) {
    state.scramblingStats = scramblingStats;
  },
  SET_SCRAMBLING_STATS_IDS(state, scramblingStats) {
    state.scramblingStatsIds = scramblingStats;
  },
  SET_PUTTING_STATS(state, puttingStats) {
    state.puttingStats = puttingStats;
  },
  SET_PUTTING_STATS_IDS(state, puttingStats) {
    state.puttingStatsIds = puttingStats;
  },
};

export const actions = {
  // Get basic score data for all player rounds
  async getBasicScores({ commit }, id) {
    try {
      const response = await EventService.getBasicScores(id);
      const basicScores = response.data;
      commit("SET_BASIC_SCORES_DATA", basicScores); // only if await promise is resolved not rejected
    } catch (err) {
      console.log(`playerData.js getBasicScores error: ${err}`);
    }
  },
  async getBasicScoresIds({ commit }, ids) {
    try {
      const response = await EventService.getBasicScoresIds(ids);
      const basicScores = response.data;
      commit("SET_BASIC_SCORES_IDS_DATA", basicScores); // only if await promise is resolved not rejected
    } catch (err) {
      console.log(`playerData.js getBasicScoresIds error: ${err}`);
    }
  },
  async getDrivingStats({ commit }, id) {
    try {
      const response = await EventService.getDrivingStats(id);
      const drivingStats = response.data;
      commit("SET_DRIVING_STATS", drivingStats); // only if await promise is resolved not rejected
    } catch (err) {
      console.log(`playerData.js getDrivingStats error: ${err}`);
    }
  },
  async getDrivingStatsIds({ commit }, ids) {
    try {
      const response = await EventService.getDrivingStatsIds(ids);
      const drivingStats = response.data;
      commit("SET_DRIVING_STATS_IDS", drivingStats); // only if await promise is resolved not rejected
    } catch (err) {
      console.log(`playerData.js getDrivingStats error: ${err}`);
    }
  },
  async getApproachStats({ commit }, id) {
    try {
      const response = await EventService.getApproachStats(id);
      const approachStats = response.data;
      commit("SET_APPROACH_STATS", approachStats); // only if await promise is resolved not rejected
    } catch (err) {
      console.log(`playerData.js getApproachStats error: ${err}`);
    }
  },
  async getApproachStatsIds({ commit }, ids) {
    try {
      const response = await EventService.getApproachStatsIds(ids);
      const approachStats = response.data;
      commit("SET_APPROACH_STATS_IDS", approachStats); // only if await promise is resolved not rejected
    } catch (err) {
      console.log(`playerData.js getApproachStats error: ${err}`);
    }
  },
  async getScramblingStats({ commit }, id) {
    try {
      const response = await EventService.getScramblingStats(id);
      const scramblingStats = response.data;
      commit("SET_SCRAMBLING_STATS", scramblingStats); // only if await promise is resolved not rejected
    } catch (err) {
      console.log(`playerData.js getScramblingStats error: ${err}`);
    }
  },
  async getScramblingStatsIds({ commit }, ids) {
    try {
      const response = await EventService.getScramblingStatsIds(ids);
      const scramblingStats = response.data;
      commit("SET_SCRAMBLING_STATS_IDS", scramblingStats); // only if await promise is resolved not rejected
    } catch (err) {
      console.log(`playerData.js getApproachStatsIds error: ${err}`);
    }
  },
  async getPuttingStats({ commit }, id) {
    try {
      const response = await EventService.getPuttingStats(id);
      const puttingStats = response.data;
      commit("SET_PUTTING_STATS", puttingStats); // only if await promise is resolved not rejected
    } catch (err) {
      console.log(`playerData.js getPuttingStats error: ${err}`);
    }
  },
  async getPuttingStatsIds({ commit }, ids) {
    try {
      const response = await EventService.getPuttingStatsIds(ids);
      const puttingStats = response.data;
      commit("SET_PUTTING_STATS_IDS", puttingStats); // only if await promise is resolved not rejected
    } catch (err) {
      console.log(`playerData.js getPuttingStatsIds error: ${err}`);
    }
  },
};

export const getters = {};
