import EventService from "@/services/EventService.js";

export const namespaced = true;

export const state = {
  postRoundData: {}, // RoundData that was posted NOT FROM A RESPONSE
  roundsData: [], // All roundData associated with a single round ID
  updateHoleData: {}, // Updated hole data
  roundAggregateData: [], // All aggregate data for a single round
};

export const mutations = {
  SET_POST_ROUND_DATA(state, roundData) {
    state.postRoundData = roundData;
  },
  SET_ROUNDS_DATA(state, roundsData) {
    state.roundsData = roundsData;
  },
  SET_UPDATE_ROUND_DATA(state, holeData) {
    state.updateHoleResponse = holeData;
  },
  SET_ROUND_AGGREGATE_DATA(state, roundAggregateData) {
    state.roundAggregateData = roundAggregateData;
  },
  CLEAR_ALL_DATA(state) {
    state.postRoundData = {};
    state.roundsData = {};
    state.updateHoleData = "";
  },
};
// Post a new HOLE for the selected round

export const actions = {
  async postRoundData({ commit }, roundData) {
    try {
      const response = await EventService.postRoundData(roundData);
      //commit("SET_POST_ROUND_DATA", roundData); // only if await promise is resolved not rejected
      // *** N.B. **** NOW RETURNS ALL THE HOLES for given roundID (all roundData)
      const roundsData = response.data;
      commit("SET_ROUNDS_DATA", roundsData);
      console.log(`roundData.js response: ${response.status}`);
    } catch (err) {
      console.log(`roundData.js postRoundData error: ${err}`);
    }
  },
  // Update a SELECTED HOLE for the chosen round
  async updateRoundData({ commit }, { holeId, holeData }) {
    try {
      const response = await EventService.updateRoundData(holeId, holeData);
      console.log(`roundData.js updateRoundData status: ${response.status}`);
      // commit("SET_UPDATE_ROUND_DATA", holeData);
      // *** N.B. **** NOW RETURNS ALL THE HOLES for given roundID (all roundData)
      const roundsData = response.data;
      commit("SET_ROUNDS_DATA", roundsData);
    } catch (error) {
      console.log(`Error: roundData.js updateRoundData: ${error}`);
    }
  },
  // Get all the roundData associated with a ROUND ID
  async getRoundsById({ commit }, id) {
    try {
      const response = await EventService.getRoundsById(id);
      const roundsData = response.data;
      // console.log(`In round.js AFTER CALL`);
      // console.log(`roundsData: ${roundsData}`);
      commit("SET_ROUNDS_DATA", roundsData); // only if await promise is resolved not rejected
      console.log(`roundData.js response: ${response.status}`);
    } catch (err) {
      console.log(`roundData.js getRoundsById error: ${err}`);
    }
  },
  // Get all aggregate data for a single round
  async getAggregateSingleRound({ commit }, id) {
    try {
      const response = await EventService.getAggregateSingleRound(id);
      const roundAggregateData = response.data;
      // console.log(`In round.js AFTER CALL`);
      // console.log(`roundsData: ${roundsData}`);
      commit("SET_ROUND_AGGREGATE_DATA", roundAggregateData); // only if await promise is resolved not rejected
      console.log(`roundData.js response: ${response.status}`);
    } catch (err) {
      console.log(`roundData.js getRoundsById error: ${err}`);
    }
  },
};

export const getters = {};
