import EventService from "@/services/EventService.js";
import axios from "axios";

export const namespaced = true;

export const state = {
  AllPlayers: [], // Array of all player objects
  player: null,
  registration: null,
  loggedIn: "",
  updatePercist: "", // Value column returned from perist update
  percist: [], // Value column from add and key SELECT
};

export const mutations = {
  SET_ALL_PLAYERS(state, players) {
    state.AllPlayers = players;
  },
  SET_UPDATE_PERCIST(state, percistData) {
    state.updatePercist = percistData;
  },
  SET_PLAYER(state, responseData) {
    state.player = responseData;
    localStorage.setItem("player", JSON.stringify(responseData));
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${responseData[0].token}`;
  },
  SET_REGISTRATION(state, responseData) {
    state.registration = responseData;
  },
  CLEAR_PLAYER(state) {
    state.player = null;
    localStorage.removeItem("player");
    axios.defaults.headers.common["Authorization"] = null;
    // reload (refresh) page clears out axios and state data FOR THIS PAGE
    // location.reload();
  },
  SET_PERCIST(state, percistData) {
    state.percist = percistData;
  },
  CLEAR_ALL_DATA(state) {
    state.AllPlayers = [];
    state.updatePercist = "";
    state.percist = "";
  },
};

export const actions = {
  async getAllPlayers({ commit }) {
    try {
      const res = await EventService.getAllPlayers();
      const players = res.data;
      //console.log(`player.js players: ${players}`);
      commit("SET_ALL_PLAYERS", players); // only if await promise is resolved not rejected
      console.log(
        `All players in store. (player.js res.status: ${res.status})`
      );
    } catch (err) {
      console.log(`player.js getAllPlayers error: ${err}`);
    }
  },
  // post registration
  async postRegistration({ commit }, registrationData) {
    try {
      const response = await EventService.postRegistration(registrationData);
      console.log(`player.js postRegistration status: ${response.status}`);
      const responseData = response.data;
      // console.log(`PLAYER: responseData[0].token: ${responseData[0].token}`);
      commit("SET_REGISTRATION", responseData);
    } catch (err) {
      console.log(`Error: player.js postRegistration: ${err}`);
    }
  },
  // login
  async login({ commit }, loginData) {
    try {
      const response = await EventService.login(loginData);
      console.log(`player.js login status: ${response.status}`);
      const responseData = response.data;
      console.log(
        `player.js responseData[0].message: ${responseData[0].message}`
      );
      if (responseData[0].message == "No Match") {
        return responseData;
      } else {
        commit("SET_PLAYER", responseData);
        return responseData;
      }
    } catch (err) {
      console.log(`Error: player.js login: ${err}`);
    }
  },
  // automaticLogin
  async automaticLogin({ commit }, responseData) {
    // console.log(`In PlayerresponseData[0].token: ${responseData[0].token} `);
    commit("SET_PLAYER", responseData);
  },
  // logout
  async logout({ commit }) {
    try {
      commit("CLEAR_PLAYER");
      console.log("IN LOGOUT");
    } catch (err) {
      console.log(`Error: player.js logout: ${err}`);
    }
  },
  // Update a percist
  async updatePercist({ commit }, percist) {
    try {
      const response = await EventService.updatePercist(percist);
      console.log(`player.js updatePercist status: ${response.status}`);
      const percistData = response.data;
      commit("SET_UPDATE_PERCIST", percistData);
    } catch (error) {
      console.log(`Error: player.js updatePercist: ${error}`);
    }
  },
  // Get a percist - using app and key for "WHEN"
  async getPercist({ commit }, percist) {
    try {
      const response = await EventService.getPercist(percist);
      console.log(`player.js getPercist status: ${response.status}`);
      const percistData = response.data;
      console.log(`IN PLAYER percistData[0].value: ${percistData[0].value}`);
      commit("SET_PERCIST", percistData);
    } catch (error) {
      console.log(`Error: player.js getPercist: ${error}`);
    }
  },
};

export const getters = {
  loggedIn(state) {
    // !! == truthiness or falsiness of values in player
    // if state.player = [] == false, state.player = [data] == true,
    return !!state.player;
  },
};
