import { createStore } from "vuex";
import * as round from "@/store/round.js";
import * as player from "@/store/player.js";
import * as course from "@/store/course.js";
import * as roundData from "@/store/roundData.js";
import * as club from "@/store/club.js";
import * as playerData from "@/store/playerData.js";

export default createStore({
  modules: {
    round,
    player,
    course,
    roundData,
    club,
    playerData,
  },
  state: {},
  mutations: {},
  actions: {
    clearAll({ commit }) {
      commit("round/CLEAR_ALL_DATA");
    },
  },
  getters: {},
});
