<template>
  <div>Logging Out ...</div>
</template>
<script>
import { onMounted } from "vue";
import router from "../router";
import store from "../store";
export default {
  name: "Logout",
  setup() {
    onMounted(async () => {
      try {
        await store.dispatch("player/logout");
        router.push({ name: "Home" });
      } catch (err) {
        console.log(`Error: Logout.vue onMounted: ${err}`);
      }
    });
  },
};
</script>
