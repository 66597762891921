import axios from "axios";

// Automate baseURL selection
var connectURL = "";
if (process.env.NODE_ENV == "development") {
  connectURL = "http://localhost:3000/";
} else {
  connectURL = "https://mjm-g-data-api.onrender.com";
}
console.log(`connectURL: ${connectURL}`);

const apiClient = axios.create({
  // baseURL: "http://localhost:3000/", // URL for local postgres db
  // baseURL: "https://mjm-g-data-api.herokuapp.com/", // To herokuapp
  // baseURL: "https://mjm-g-data-api.onrender.com", // URL to Render
  baseURL: connectURL,
  withCredentials: false,
  headers: {
    Accept: "application/json", // Any authentication & configuration
    "Content-Type": "application/json", // that the API server may need - see axios docs
  },
});

export default {
  // Rounds
  async getInOrCompleteRoundsById(id, data_entered) {
    try {
      return await apiClient.get(`/api/rounds/${id}/${data_entered}`);
    } catch (err) {
      console.log(`EventService.js getAllRounds error: ${err}`);
    }
  },
  async postRound(round) {
    try {
      // console.log("IN EVENTSERVICE");
      return await apiClient.post("/api/rounds", round);
    } catch (error) {
      console.log(`EventService.js postRound error: ${error}`);
    }
  },
  async roundCompleted(roundId, roundData) {
    try {
      console.log(`EVENT SERVICE roundData: ${roundData}`);
      return await apiClient.patch(`/api/rounds/${roundId}`, roundData);
    } catch (error) {
      console.log(`Error: EventService.js roundCompleted: ${error}`);
    }
  },

  // Update data in chosen HOLE within roundData DB
  async updateRoundData(holeId, holeData) {
    try {
      // console.log(`IN EVENTSERVICE updateRoundData`)
      return await apiClient.put(`/api/round-data/${holeId}`, holeData);
    } catch (error) {
      console.log(`Error: EventService.js updateRoundData: ${error}`);
    }
  },
  // Clubs
  async getClubs() {
    try {
      return await apiClient.get("/api/clubs");
    } catch (err) {
      console.log(`Error: EnterData.vue getClubs: ${err}`);
    }
  },
  // Players
  async getAllPlayers() {
    try {
      return await apiClient.get("/api/players");
    } catch (err) {
      console.log(`EventService.js getAllPlayers error: ${err}`);
    }
  },
  async postRegistration(registrationData) {
    try {
      // console.log("IN EVENTSERVICE");
      return await apiClient.post("/api/players", registrationData);
    } catch (error) {
      console.log(`EventService.js postRegistration error: ${error}`);
    }
  },
  async login(loginData) {
    try {
      // console.log("IN EVENTSERVICE");
      return await apiClient.patch("/api/players", loginData);
    } catch (error) {
      console.log(`EventService.js login error: ${error}`);
      return error;
    }
  },
  // PLAYERS DATA
  async getBasicScores(id) {
    try {
      return await apiClient.get(`/api/player-aggregate/${id}`);
    } catch (err) {
      console.log(`EventService.js getBasicScores error: ${err}`);
    }
  },
  async getBasicScoresIds(ids) {
    try {
      return await apiClient.patch("/api/player-aggregate-ids", ids);
    } catch (err) {
      console.log(`EventService.js getBasicScoresIds error: ${err}`);
    }
  },
  async getDrivingStats(id) {
    try {
      return await apiClient.get(`/api/player-agg-driving/${id}`);
    } catch (err) {
      console.log(`EventService.js getDrivingStats error: ${err}`);
    }
  },
  async getDrivingStatsIds(ids) {
    try {
      return await apiClient.patch("/api/player-agg-ids-driving", ids);
    } catch (err) {
      console.log(`EventService.js getDrivingStatsIds error: ${err}`);
    }
  },
  async getApproachStats(id) {
    try {
      return await apiClient.get(`/api/player-agg-approach/${id}`);
    } catch (err) {
      console.log(`EventService.js getApproachStats error: ${err}`);
    }
  },
  async getApproachStatsIds(ids) {
    try {
      return await apiClient.patch("/api/player-agg-ids-approach", ids);
    } catch (err) {
      console.log(`EventService.js getApproachStatsIds error: ${err}`);
    }
  },
  async getScramblingStats(id) {
    try {
      return await apiClient.get(`/api/player-agg-scrambling/${id}`);
    } catch (err) {
      console.log(`EventService.js getScramblingStats error: ${err}`);
    }
  },
  async getScramblingStatsIds(ids) {
    try {
      return await apiClient.patch("/api/player-agg-ids-scrambling", ids);
    } catch (err) {
      console.log(`EventService.js getScramblingStatsIds error: ${err}`);
    }
  },
  async getPuttingStats(id) {
    try {
      return await apiClient.get(`/api/player-agg-putting/${id}`);
    } catch (err) {
      console.log(`EventService.js getPuttingStats error: ${err}`);
    }
  },
  async getPuttingStatsIds(ids) {
    try {
      return await apiClient.patch("/api/player-agg-ids-putting", ids);
    } catch (err) {
      console.log(`EventService.js getPuttingStatsIds error: ${err}`);
    }
  },
  // Percist
  async updatePercist(percist) {
    try {
      // console.log(`IN EVENTSERVICE updateRoundData`)
      return await apiClient.put("/api/percist/update", percist);
    } catch (error) {
      console.log(`Error: EventService.js updatePercist: ${error}`);
    }
  },
  async getPercist(percist) {
    try {
      // console.log(`IN EVENTSERVICE updateRoundData`)
      return await apiClient.put("/api/percist/", percist);
    } catch (error) {
      console.log(`Error: EventService.js getPercist: ${error}`);
    }
  },
  // Courses
  async getAllCourses() {
    try {
      return await apiClient.get("/api/courses");
    } catch (err) {
      console.log(`EventService.js getAllCourses error: ${err}`);
    }
  },
  async getCourseFromRoundId(id) {
    try {
      console.log("IN EVENTSERVICE");
      // console.log(`id: ${id}`);
      return apiClient.get(`api/round-course/${id}`);
    } catch (error) {
      console.log(`EventService.js getCourseFromRoundId error: ${error}`);
    }
  },
  // RoundData
  async postRoundData(roundData) {
    try {
      console.log("IN EVENTSERVICE");
      return await apiClient.post("/api/round-data", roundData);
    } catch (error) {
      console.log(`EventService.js postRoundData error: ${error}`);
    }
  },
  async getRoundsById(id) {
    try {
      console.log("IN EVENTSERVICE");
      // console.log(`id: ${id}`);
      return apiClient.get(`api/round-data/${id}`);
    } catch (error) {
      console.log(`EventService.js getRoundsById error: ${error}`);
    }
  },
  async getAggregateSingleRound(id) {
    try {
      console.log("IN EVENTSERVICE");
      // console.log(`id: ${id}`);
      return apiClient.get(`api/round-data-agg1/${id}`);
    } catch (error) {
      console.log(`EventService.js getAggregateSingleRound error: ${error}`);
    }
  },
};
