<template>
  <Dialog v-model:visible="dialogDisplay">
    <template #header> Problem with Login </template>
    <p>Either user name or password are incorrect</p>
    <template #footer class="p-d-flex p-jc-center">
      <Button label="Try Again" @click="dialogClose" />
      <Button label="Quit" @click="resetAndHome" />
    </template>
  </Dialog>
  <div class="p-grid p-jc-center">
    <div class="p-col-12 p-md-6">
      <Card class="p-mt-4">
        <template #title> Login </template>
        <template #content>
          <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
            <div class="p-field">
              <div class="p-float-label">
                <InputText
                  id="userName"
                  v-model="v$.userName.$model"
                  :class="{ 'p-invalid': v$.userName.$invalid && submitted }"
                />
                <label
                  for="userName"
                  :class="{ 'p-error': v$.userName.$invalid && submitted }"
                  >user name*</label
                >
              </div>
              <small
                v-if="
                  (v$.userName.$invalid && submitted) ||
                  v$.userName.$pending.$response
                "
                class="p-error"
                >{{
                  v$.userName.required.$message.replace("Value", "user name")
                }}</small
              >
            </div>
            <div class="p-field">
              <div class="p-float-label">
                <Password
                  id="password"
                  v-model="v$.password.$model"
                  :class="{ 'p-invalid': v$.password.$invalid && submitted }"
                  :feedback="false"
                  toggleMask
                />
                <label
                  for="password"
                  :class="{ 'p-error': v$.password.invalid && submitted }"
                  >password*</label
                >
              </div>
              <small
                v-if="
                  (v$.password.$invalid && submitted) ||
                  v$.password.$pending.$response
                "
                class="p-error"
                >{{
                  v$.password.required.$message.replace("Value", "password")
                }}</small
              >
            </div>
            <Button type="submit" label="Submit" class="p-mt-2 p-mb-4" />
            <router-link :to="{ name: 'Register' }">
              Don't have an account? Register.
            </router-link>
          </form>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import router from "../router";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import store from "../store";
export default {
  name: "Login",
  setup() {
    const dialogDisplay = ref(false);
    const submitted = ref(false);
    const state = reactive({
      userName: "",
      password: "",
    });
    const rules = {
      userName: { required },
      password: { required },
    };
    const v$ = useVuelidate(rules, state);
    function handleSubmit(isFormValid) {
      submitted.value = true;

      if (!isFormValid) {
        return;
      }
      login();
    }

    async function login() {
      // TO-DO:  GET CLUBS_ID
      try {
        const loginData = {
          user_name: state.userName,
          password: state.password,
        };
        var response = await store.dispatch("player/login", loginData);
        console.log(`Login.vue: response: ${response[0].message}`);
        if (response[0].message == "No Match") {
          dialogOpen();
          resetForm();
          // console.log(`Login: response[0].message ${response[0].message}`);
        } else {
          resetAndHome();
        }
      } catch (err) {
        console.log(`Error: Login.vue login: ${err}`);
      }
    }

    function resetForm() {
      state.userName = "";
      state.password = "";
      submitted.value = false;
    }

    function dialogClose() {
      dialogDisplay.value = false;
    }

    function dialogOpen() {
      dialogDisplay.value = true;
    }

    function resetAndHome() {
      resetForm();
      router.push({ name: "Home" });
    }

    return {
      state,
      v$,
      submitted,
      handleSubmit,
      resetForm,
      dialogClose,
      dialogDisplay,
      resetAndHome,
    };
  },
};
</script>
