import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import CreateRound from "../views/CreateRound.vue";
import EnterData from "../views/EnterData.vue";
import Register from "../views/Register.vue";
import Login from "../views/Login.vue";
import Logout from "../views/Logout.vue";
import Settings from "../views/Settings.vue";
import GeneralErr from "../views/GeneralErr.vue";
import GraphsAllData from "../views/GraphsAllData.vue";
import GraphsOneOrMore from "../views/GraphsOneOrMore.vue";

import store from "../store";

const routes = [
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/enter-data",
    name: "EnterData",
    component: EnterData,
    async beforeEnter() {
      try {
        if (store.getters["player/loggedIn"]) {
          return true;
        } else {
          return { name: "Login" };
        }
      } catch (err) {
        console.log(`Error: index.js EnterData beforeEnter: ${err}`);
      }
    },
  },
  {
    path: "/create-round",
    name: "CreateRound",
    component: CreateRound,
    async beforeEnter() {
      try {
        if (store.getters["player/loggedIn"]) {
          return true;
        } else {
          return { name: "Login" };
        }
      } catch (err) {
        console.log(`Error: index.js EnterData beforeEnter: ${err}`);
      }
    },
  },
  {
    path: "/graphs-all-data",
    name: "GraphsAllData",
    component: GraphsAllData,
    async beforeEnter() {
      try {
        if (store.getters["player/loggedIn"]) {
          return true;
        } else {
          return { name: "Login" };
        }
      } catch (err) {
        console.log(`Error: index.js GraphsAllData beforeEnter: ${err}`);
      }
    },
  },
  {
    path: "/graphs-one-or-more",
    name: "GraphsOneOrMore",
    component: GraphsOneOrMore,
    async beforeEnter() {
      try {
        if (store.getters["player/loggedIn"]) {
          return true;
        } else {
          return { name: "Login" };
        }
      } catch (err) {
        console.log(`Error: index.js GraphOneOrMore beforeEnter: ${err}`);
      }
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: GeneralErr,
    props: true,
  },
  {
    path: "/GeneralErr",
    name: "GeneralErr",
    component: GeneralErr,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
