<template>
  <div class="p-grid p-jc-center">
    <div class="p-col-12, p-md-6">
      <Card class="p-mt-4">
        <template #title>Settings</template>
        <template #content>
          <form @submit.prevent="formSubmit()" class="p-fluid">
            <InputSwitch v-model="checked" />
            <Button type="submit" label="Submit" class="p-mt-2" />
          </form>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "Settings",
  setup() {
    const checked = ref(false);
    function formSubmit() {
      //changeLoggedIn();
    }
    // async function changeLoggedIn() {
    //   try {
    //     const percist = {
    //       app: "mjm-g-data",
    //       description: "logged_in",
    //       value: checked.value,
    //     };
    //     await store.dispatch("player/updatePercist", percist);
    //     await store.dispatch("player/getPercist", percist);
    //     // change the login value
    //     store.state.player.loggedIn = store.state.player.percist[0].value;
    //     // console.log(`percistValue:${store.state.player.percist[0].value}`);
    //   } catch (error) {
    //     console.log(`Error: Settings changeLoggedIn: ${error}`);
    //   }
    // }

    return {
      formSubmit,
      checked,
    };
  },
};
</script>
