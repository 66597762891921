<template>
  <div class="p-grid max-width">
    <h1 class="p-col-12 p-d-flex p-jc-between">
      Enter Hole Data
      <Button
        class="p-button-rounded p-button-text p-button-lg"
        @click="toggleSelections"
        :icon="iconEye"
      />
    </h1>

    <!-- Dropdown -->
    <div class="p-col-12 p-d-grid p-ai-center p-jc-center p-mb-5 selections">
      <div>
        <!-- <label class="p-mr-1 bSize1">Rounds:</label> -->
        <Dropdown
          v-model="chosenRoundId"
          :options="rounds"
          optionValue="id"
          dataKey="id"
          optionLabel="date_name"
          placeholder="Choose Round"
          @change="selectedRoundId()"
          class="bSize1 p-mr-1"
        />
        <!-- <label class="p-mr-1 bSize1">go to hole:</label> -->
        <Dropdown
          v-model="chosenHoleNumber"
          :options="holes"
          optionValue="hole"
          dataKey="hole"
          optionLabel="hole"
          placeholder="Hole"
          @change="selectedHole()"
        />
      </div>
    </div>
    <div class="p-col-12 divider"></div>
    <!-- Data Entry and Post Buttons -->
    <div class="p-col-12 p-md-6 p-order-md-2">
      <div class="minHeight">
        <div class="bSize1 p-p-2 dataEntryHeader">
          {{ holeDataEntryText }}
        </div>
        <!-- @click="showUpperDataEntry()" -->
        <!-- Data Entry -->
        <div class="border">
          <div class="p-col-12">
            <!-- Upper amd lower DataEntry -->
            <div
              id="upperDataEntry1"
              class="p-col-12 p-mt-1 p-pb-2 upperDataEntry"
            >
              <!-- Upper DataEntry -->
              <div class="p-d-flex p-jc-center fontLarge">
                <div class="p-mr-1 p-text-bold">Hole No:</div>
                <div class="displayBox1 p-mr-3">{{ hole_number }}</div>
                <div class="p-mr-1 p-text-bold">Par:</div>
                <div class="displayBox1">{{ par }}</div>
              </div>
              <!-- Lower DatEntry -->
              <div class="p-d-flex p-jc-around p-mt-2">
                <!-- Score -->
                <div class="p-d-flex p-flex-column p-ai-center">
                  <div class="p-mb-1">
                    <Button
                      label="S"
                      @click="toggleInputBackgrounds('score')"
                      class="bSize1 p-button-secondary"
                    />
                  </div>
                  <div id="score" class="displayBox1">
                    {{ scoreEvaluation }}
                  </div>
                </div>
                <!-- Fairway -->
                <div class="p-d-flex p-flex-column p-ai-center">
                  <div class="p-mb-1">
                    <Button
                      label="F"
                      @click="toggleInputBackgrounds('fairway')"
                      class="bSize1 p-button-secondary"
                    />
                  </div>
                  <div id="fairway" class="displayBox1">
                    {{ fairwayEvaluation }}
                  </div>
                </div>
                <!-- Green -->
                <div class="p-d-flex p-flex-column p-ai-center">
                  <div class="p-mb-1">
                    <Button
                      label="G"
                      @click="toggleInputBackgrounds('green')"
                      class="bSize1 p-button-secondary"
                    />
                  </div>
                  <div id="green" class="displayBox1">
                    {{ greenEvaluation }}
                  </div>
                </div>
                <!-- Putting -->
                <div class="p-d-flex p-flex-column p-ai-center">
                  <div class="p-mb-1">
                    <Button
                      label="P"
                      @click="toggleInputBackgrounds('putting')"
                      class="bSize1 p-button-secondary"
                    />
                  </div>
                  <div id="putting" class="displayBox1">
                    {{ puttingEvaluation }}
                  </div>
                </div>
                <!-- Scrambling -->
                <div class="p-d-flex p-flex-column p-ai-center">
                  <div class="p-mb-1">
                    <Button
                      label="U/D"
                      @click="toggleInputBackgrounds('scrambling')"
                      class="bSize1 p-button-secondary"
                    />
                  </div>
                  <div id="scrambling" class="displayBox1">
                    {{ scramblingEvaluation }}
                  </div>
                </div>
              </div>
            </div>
            <!-- All buttons for data entry -->
            <div id="allDataEntry" class="allDataEntry">
              <!-- Buttons for all data entry -->
              <div>
                <!-- Score -->
                <div class="p-d-flex p-jc-around">
                  <Button
                    @click="shotEvaluation('score', 1)"
                    class="p-button-secondary p-button-rounded score choiceButton displayNone largeFontSpacing"
                  >
                    1
                  </Button>
                  <Button
                    @click="shotEvaluation('score', 2)"
                    class="p-button-secondary p-button-rounded score choiceButton displayNone largeFontSpacing"
                  >
                    2
                  </Button>
                  <Button
                    @click="shotEvaluation('score', 3)"
                    class="p-button-secondary p-button-rounded score choiceButton displayNone largeFontSpacing"
                  >
                    3
                  </Button>
                </div>
                <div class="p-d-flex p-jc-around">
                  <Button
                    @click="shotEvaluation('score', 4)"
                    class="p-button-secondary p-button-rounded score choiceButton displayNone largeFontSpacing"
                  >
                    4
                  </Button>
                  <Button
                    @click="shotEvaluation('score', 5)"
                    class="p-button-secondary p-button-rounded score choiceButton displayNone largeFontSpacing"
                  >
                    5
                  </Button>
                  <Button
                    @click="shotEvaluation('score', 6)"
                    class="p-button-secondary p-button-rounded score choiceButton displayNone largeFontSpacing"
                  >
                    6
                  </Button>
                </div>
                <div class="p-d-flex p-jc-around">
                  <Button
                    @click="shotEvaluation('score', 7)"
                    class="p-button-secondary p-button-rounded score choiceButton displayNone largeFontSpacing"
                  >
                    7
                  </Button>
                  <Button
                    @click="shotEvaluation('score', 8)"
                    class="p-button-secondary p-button-rounded score choiceButton displayNone largeFontSpacing"
                  >
                    8
                  </Button>
                  <Button
                    @click="shotEvaluation('score', 9)"
                    class="p-button-secondary p-button-rounded score choiceButton displayNone largeFontSpacing"
                  >
                    9
                  </Button>
                </div>
                <!-- Fairway -->
                <div class="p-d-flex p-jc-around">
                  <Button
                    @click="shotEvaluation('fairway', 'L')"
                    class="p-button-secondary p-button-rounded fairway choiceButton displayNone largeFontSpacing"
                  >
                    long
                  </Button>
                </div>
                <div class="p-d-flex p-jc-around">
                  <Button
                    @click="shotEvaluation('fairway', 'Lt')"
                    class="p-button-secondary p-button-rounded fairway choiceButton displayNone largeFontSpacing"
                  >
                    left
                  </Button>
                  <Button
                    @click="shotEvaluation('fairway', 'H')"
                    class="p-button-secondary p-button-rounded fairway choiceButton displayNone largeFontSpacing"
                  >
                    hit
                  </Button>
                  <Button
                    @click="shotEvaluation('fairway', 'Rt')"
                    class="p-button-secondary p-button-rounded fairway choiceButton displayNone largeFontSpacing"
                  >
                    right
                  </Button>
                </div>
                <div class="p-d-flex p-jc-around">
                  <Button
                    @click="shotEvaluation('fairway', 'S')"
                    class="p-button-secondary p-button-rounded fairway choiceButton displayNone largeFontSpacing"
                  >
                    short
                  </Button>
                </div>

                <!-- Green -->
                <div class="p-d-flex p-jc-around">
                  <!-- <Button
                    @click="shotEvaluation('green', 'Llt')"
                    class="p-button-secondary p-button-rounded green choiceButton displayNone largeFontSpacing"
                  >
                    long lt
                  </Button> -->
                  <Button
                    @click="shotEvaluation('green', 'L')"
                    class="p-button-secondary p-button-rounded green choiceButton displayNone largeFontSpacing"
                  >
                    long
                  </Button>
                  <!-- <Button
                    @click="shotEvaluation('green', 'Lrt')"
                    class="p-button-secondary p-button-rounded green choiceButton displayNone largeFontSpacing"
                  >
                    long rt
                  </Button> -->
                </div>
                <div class="p-d-flex p-jc-around">
                  <Button
                    @click="shotEvaluation('green', 'Lt')"
                    class="p-button-secondary p-button-rounded green choiceButton displayNone largeFontSpacing"
                  >
                    left
                  </Button>
                  <Button
                    @click="shotEvaluation('green', 'H')"
                    class="p-button-secondary p-button-rounded green choiceButton displayNone largeFontSpacing"
                  >
                    hit
                  </Button>
                  <Button
                    @click="shotEvaluation('green', 'Rt')"
                    class="p-button-secondary p-button-rounded green choiceButton displayNone largeFontSpacing"
                  >
                    right
                  </Button>
                </div>
                <div class="p-d-flex p-jc-around">
                  <!-- <Button
                    @click="shotEvaluation('green', 'Slt')"
                    class="p-button-secondary p-button-rounded green choiceButton displayNone largeFontSpacing"
                  >
                    short lt
                  </Button> -->

                  <Button
                    @click="shotEvaluation('green', 'S')"
                    class="p-button-secondary p-button-rounded green choiceButton displayNone largeFontSpacing"
                  >
                    short
                  </Button>

                  <!-- <Button
                    @click="shotEvaluation('green', 'Srt')"
                    class="p-button-secondary p-button-rounded green choiceButton displayNone largeFontSpacing"
                  >
                    short rt
                  </Button> -->
                </div>
                <!-- Putting -->
                <div class="p-d-flex p-jc-around">
                  <Button
                    @click="shotEvaluation('putting', 0)"
                    class="p-button-secondary p-button-rounded putting choiceButton displayNone largeFontSpacing"
                  >
                    0
                  </Button>
                  <Button
                    @click="shotEvaluation('putting', 1)"
                    class="p-button-secondary p-button-rounded putting choiceButton displayNone largeFontSpacing"
                  >
                    1
                  </Button>
                </div>
                <div class="p-d-flex p-jc-around">
                  <Button
                    @click="shotEvaluation('putting', 2)"
                    class="p-button-secondary p-button-rounded putting choiceButton displayNone largeFontSpacing"
                  >
                    2
                  </Button>
                  <Button
                    @click="shotEvaluation('putting', 3)"
                    class="p-button-secondary p-button-rounded putting choiceButton displayNone largeFontSpacing"
                  >
                    3
                  </Button>
                </div>
                <div class="p-d-flex p-jc-around">
                  <Button
                    @click="shotEvaluation('putting', 4)"
                    class="p-button-secondary p-button-rounded putting choiceButton displayNone largeFontSpacing"
                  >
                    4
                  </Button>
                  <Button
                    @click="shotEvaluation('putting', 5)"
                    class="p-button-secondary p-button-rounded putting choiceButton displayNone largeFontSpacing"
                  >
                    5
                  </Button>
                </div>
                <!-- Putt Distances -->
                <div>
                  <div class="p-d-flex p-jc-around">
                    <Button
                      @click="shotEvaluation('puttDistance', '0')"
                      class="p-button-secondary p-button-rounded puttDistance choiceButton displayNone puttDistanceSpacing"
                    >
                      0
                    </Button>
                    <Button
                      @click="shotEvaluation('puttDistance', '1')"
                      class="p-button-secondary p-button-rounded puttDistance choiceButton displayNone puttDistanceSpacing"
                    >
                      1
                    </Button>
                    <Button
                      @click="shotEvaluation('puttDistance', '2')"
                      class="p-button-secondary p-button-rounded puttDistance choiceButton displayNone puttDistanceSpacing"
                    >
                      2
                    </Button>
                    <Button
                      @click="shotEvaluation('puttDistance', '3')"
                      class="p-button-secondary p-button-rounded puttDistance choiceButton displayNone puttDistanceSpacing"
                    >
                      3
                    </Button>
                  </div>
                  <div class="p-d-flex p-jc-around">
                    <Button
                      @click="shotEvaluation('puttDistance', '4')"
                      class="p-button-secondary p-button-rounded puttDistance choiceButton displayNone puttDistanceSpacing"
                    >
                      4
                    </Button>
                    <Button
                      @click="shotEvaluation('puttDistance', '5')"
                      class="p-button-secondary p-button-rounded puttDistance choiceButton displayNone puttDistanceSpacing"
                    >
                      5
                    </Button>
                    <Button
                      @click="shotEvaluation('puttDistance', '6')"
                      class="p-button-secondary p-button-rounded puttDistance choiceButton displayNone puttDistanceSpacing"
                    >
                      6
                    </Button>
                    <Button
                      @click="shotEvaluation('puttDistance', '7')"
                      class="p-button-secondary p-button-rounded puttDistance choiceButton displayNone puttDistanceSpacing"
                    >
                      7
                    </Button>
                  </div>
                  <div class="p-d-flex p-jc-around">
                    <Button
                      @click="shotEvaluation('puttDistance', '8')"
                      class="p-button-secondary p-button-rounded puttDistance choiceButton displayNone puttDistanceSpacing"
                    >
                      8
                    </Button>
                    <Button
                      @click="shotEvaluation('puttDistance', '9')"
                      class="p-button-secondary p-button-rounded puttDistance choiceButton displayNone puttDistanceSpacing"
                    >
                      9
                    </Button>
                    <Button
                      @click="shotEvaluation('puttDistance', '.')"
                      class="p-button-secondary p-button-rounded puttDistance choiceButton displayNone puttDistanceSpacing"
                    >
                      '.'
                    </Button>
                  </div>
                  <div class="p-d-flex p-jc-around">
                    <div id="putt1DistParent" @click="puttDistanceFocus(1)">
                      <InputText
                        type="text"
                        v-model="putt1Distance"
                        disabled
                        :class="putt1DistanceBC"
                        class="puttDistanceInput puttDistance choiceButton displayNone puttDistanceSpacing"
                      />
                    </div>
                    <div @click="puttDistanceFocus(2)">
                      <InputText
                        type="text"
                        v-model="putt2Distance"
                        disabled
                        :class="putt2DistanceBC"
                        class="puttDistanceInput puttDistance choiceButton displayNone puttDistanceSpacing"
                      />
                    </div>
                  </div>
                </div>
                <!-- Scrambliing -->
                <div>
                  <div class="p-d-flex p-jc-around">
                    <Button
                      @click="shotEvaluation('scrambling', 'Fy')"
                      class="p-button-secondary p-button-rounded scrambling choiceButton displayNone largeFontSpacing"
                    >
                      f_way-yes
                    </Button>
                    <Button
                      @click="shotEvaluation('scrambling', 'Fn')"
                      class="p-button-secondary p-button-rounded scrambling choiceButton displayNone largeFontSpacing"
                    >
                      no
                    </Button>
                  </div>
                  <div class="p-d-flex p-jc-around">
                    <Button
                      @click="shotEvaluation('scrambling', 'Ry')"
                      class="p-button-secondary p-button-rounded scrambling choiceButton displayNone largeFontSpacing"
                    >
                      rough-yes
                    </Button>
                    <Button
                      @click="shotEvaluation('scrambling', 'Rn')"
                      class="p-button-secondary p-button-rounded scrambling choiceButton displayNone largeFontSpacing"
                    >
                      no
                    </Button>
                  </div>
                  <div class="p-d-flex p-jc-around">
                    <Button
                      @click="shotEvaluation('scrambling', 'By')"
                      class="p-button-secondary p-button-rounded scrambling choiceButton displayNone largeFontSpacing"
                    >
                      bunker-yes
                    </Button>
                    <Button
                      @click="shotEvaluation('scrambling', 'Bn')"
                      class="p-button-secondary p-button-rounded scrambling choiceButton displayNone largeFontSpacing"
                    >
                      no
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <!-- </div> -->
            <!-- Buttons to post / update round data -->
            <div
              id="buttonBox"
              class="p-col-12 p-d-flex p-jc-around buttonBox1"
            >
              <Button
                label="Save"
                @click="postRoundData()"
                :class="saveDisplay"
                class="bSize1"
              />
              <Button
                id="bUpdate"
                label="Update"
                @click="updateRoundData"
                :class="updateDisplay"
                class="bSize1"
              />
              <Button
                label="Clear"
                @click="clearEvaluations()"
                class="bSize1"
              />
              <Button
                label="PD"
                @click="toggleInputBackgrounds('puttDistance')"
                class="bSize1 p-button-secondary"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="p-d-flex p-jc-evenly p-mt-2" style="font-size: 22px">
        <div>
          Current: <b>{{ quickScores.currentToPar }}</b>
        </div>
        <div>
          Out: <b>{{ quickScores.firstNineFinal }}</b>
        </div>
        <div>
          In: <b>{{ quickScores.secondNineFinal }}</b>
        </div>
        <div>
          Total: <b>{{ quickScores.totalScore }}</b>
        </div>
      </div>

      <div><Toast position="center" /></div>
    </div>
    <!-- Table of completed holes -->
    <div class="p-col-12 p-md-6 p-ai-start">
      <div>
        <DataTable
          :value="completedRounds"
          v-model:selection="selectedRow"
          selectionMode="single"
          dataKey="id"
          @click="useSelectedHoleData()"
          class="p-datatable-sm"
          :paginator="true"
          :rows="9"
          stripedRows
          responsiveLayout="scroll"
          showGridlines
        >
          <template #header>
            <div class="bSize1">Table of Completed Holes</div>
          </template>
          <!-- <Column field="id" header="id"></Column> -->
          <Column
            field="hole_number"
            header="Hole"
            style="text-align: center"
          ></Column>
          <Column field="par" header="Par" style="text-align: center"></Column>
          <Column field="score" header="S" style="text-align: center">
            <template v-slot:body="slotProps">
              <div :class="scoreColor(slotProps.data)">
                {{ slotProps.data.score }}
              </div>
            </template>
          </Column>
          <Column
            field="driving"
            header="F"
            style="text-align: center"
          ></Column>
          <Column
            field="approach"
            header="G"
            style="text-align: center"
          ></Column>
          <Column
            field="putting"
            header="P"
            style="text-align: center"
          ></Column>
          <Column
            field="scrambling"
            header="U/D"
            style="text-align: center"
          ></Column>
        </DataTable>
      </div>
      <!-- <div>Selected Row: {{ selectedRow }}</div> -->

      <Button
        label="Next Hole"
        @click="completedHoles()"
        class="p-mt-2 bSize1 p-button-secondary"
      />
    </div>
    <div class="p-col-12 p-order-md-3 divider"></div>
  </div>
  <!-- GRAPHS of round data -->
  <div class="p-grid p-jc-center p-mb-4">
    <div class="p-col-12 p-d-flex p-jc-around">
      <Button label="Round Completed" @click="roundCompleted" />
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import store from "../store";
import { useToast } from "primevue/usetoast";
import gsap from "gsap";
import router from "../router";
import NProgress from "nprogress";

export default {
  name: "EnterData",
  setup() {
    // BEGIN ****** GET INFORMATION   ***********
    // All rounds from dropdown
    const rounds = computed(() => {
      return store.state.round.allInOrCompleteRounds;
    });
    onMounted(() => {
      try {
        // Get playerId from playerInfo in store
        const id = store.state.player.player[0].playerId;
        const data_entered = false;
        console.log(`EnterData onMounted: playerId: ${id}`);
        // const id = 1;
        store.dispatch("round/getInOrCompleteRoundsById", { id, data_entered });
        // clear any old table data
        store.state.roundData.roundsData = [];
      } catch (err) {
        console.log(
          `EnterData getInOrCompleteRoundsById getAllRounds error: ${err}`
        );
      }
    });
    // END            ****** GET INFORMATION   ***********

    // BEGIN        ****** DYNAMIC INTERFACE  *********
    const dataEntrySize1 = "17rem";
    const displaySelections = ref(true);
    const iconEye = ref("pi pi-eye");
    // END           ****** DYNAMIC INTERFACE  *********

    // BEGIN          ***** GET AND MANIPULATE DATA  *****
    const chosenHoleNumber = ref(null);
    const holes = ref([]);
    const putt1Distance = ref("");
    const putt2Distance = ref("");
    var puttDistanceChoice = 1;
    const putt1DistanceBC = ref("yellowBG");
    const putt2DistanceBC = ref("whiteBG");
    const steps = ref([]);
    const chosenRoundId = ref(null);
    const chosenHoleId = ref(null);
    const hole_number = ref(null);
    const course = ref({});
    var par = ref(null);
    const scoreEvaluation = ref(null);
    const puttingEvaluation = ref(null);
    const fairwayEvaluation = ref("");
    const greenEvaluation = ref("");
    const scramblingEvaluation = ref("");
    const selectedRow = ref([]);
    const holeDataEntryText = ref("NEW hole data");
    const toast = useToast();
    const updateDisplay = ref("displayNone");
    const saveDisplay = ref("display");
    // END           ***** GET AND MANIPULATE DATA  *****

    // BEGIN     ****** DYNAMIC INTERFACE  *********
    function addHighlight(x) {
      gsap.set(`#${x}`, { backgroundColor: "yellow" });
    }
    function removeHighlight() {
      gsap.set(".displayBox1", { backgroundColor: "#add8e6" });
    }
    // Dynamically modify css classes
    function toggleInputBackgrounds(x) {
      if (this.chosenRoundId == null) {
        console.log(`Choose a Round before editing`);
        alert("First Choose a Round");
      } else {
        // console.log(`THIS x: ${x}`);
        hideChoices();
        showChoice(x);
        // Remove Prefilled value and set highlight
        this.clearEvaluation(x);
      }
    }
    function hideChoices() {
      gsap.to(".choiceButton", { opacity: 0, duration: 0, display: "none" });
    }
    function toggleSelections() {
      if (displaySelections.value) {
        gsap.to(".selections", { opacity: 0, duration: 0.5, display: "none" });
        displaySelections.value = false;
        iconEye.value = "pi pi-eye-slash";
      } else {
        gsap.to(".selections", { opacity: 1, duration: 0.5, display: "block" });
        displaySelections.value = true;
        iconEye.value = "pi pi-eye";
      }
    }
    function showChoiceTimeLine() {
      const showChoice = gsap.timeline();

      showChoice.to("#allDataEntry", {
        display: "block",
        opacity: 1,
        height: dataEntrySize1,
        duration: 0.3,
        ease: "none",
      });
      return showChoice;
    }
    async function showChoice(x) {
      // Determine if dataEntry widget is fully opened
      const choiceStatus = document.getElementById("allDataEntry").style.height;
      // Fully open dataEntry widget if not open
      if (choiceStatus !== dataEntrySize1) {
        const showChoiceTL = showChoiceTimeLine();
        await showChoiceTL.play();
        gsap.set(`.${x}`, { opacity: 1, display: "block" });
      } else {
        gsap.set(`.${x}`, { opacity: 1, display: "block" });
      }
    }
    // END     ****** DYNAMIC INTERFACE  *********

    // BEGIN         ***** GET AND MANIPULATE DATA  *****
    // All completedRounds from given ID
    const completedRounds = computed(() => {
      return store.state.roundData.roundsData;
    });
    const quickScores = computed(() => {
      var currentToPar = null;
      var firstNine = null;
      var secondNine = null;
      var firstNineFinal = null;
      var secondNineFinal = null;
      var totalScore = null;
      for (const [key, value] of Object.entries(completedRounds.value)) {
        currentToPar += value.score - value.par;
        totalScore += value.score;
        if (key < 9) {
          firstNine += value.score;
        } else if (key > 8) {
          secondNine += value.score;
        }
        if (key == 8) {
          firstNineFinal = firstNine;
        }
        if (key == 17) {
          secondNineFinal = secondNine;
        }
      }
      return {
        currentToPar: currentToPar,
        firstNine: firstNine,
        secondNine: secondNine,
        firstNineFinal: firstNineFinal,
        secondNineFinal: secondNineFinal,
        totalScore: totalScore,
      };
    });
    function puttDistanceFocus(x) {
      if (x == 1) {
        puttDistanceChoice = 1;
        putt1Distance.value = "";
        putt1DistanceBC.value = "yellowBG";
        putt2DistanceBC.value = "whiteBG";
      } else if (x == 2) {
        puttDistanceChoice = 2;
        putt2Distance.value = "";
        putt2DistanceBC.value = "yellowBG";
        putt1DistanceBC.value = "whiteBG";
      }
    }
    // Create "holes as an array of objects
    function createHoles() {
      var i = 1;
      var arr = [];
      var obj = {};
      for (i; i <= 18; i++) {
        obj = { hole: i };
        arr.push(obj);
        // console.log(`arr: ${arr}`);
      }
      holes.value = arr;
      // console.log(`holes: ${holes.value}`);
    }
    // Perform functions after hole selection
    function selectedHole() {
      try {
        console.log(`chosenHoleNumber: ${chosenHoleNumber.value}`);
        // Check if 18 holes filled out - TOAST and user goes to UPDATE
        if (completedRounds.value.length == 18) {
          toast.add({
            severity: "info",
            summary: "Information Message",
            detail: `Data already entered for ALL EIGHTEEN HOLES. Update from table if required`,
            life: 3000,
          });
          chosenHoleNumber.value = null;
        } else {
          // Check to see if this hole has data entered
          // If no data entered then load hole as NEW hole
          var dataExists = false;
          for (const [key, value] of Object.entries(completedRounds.value)) {
            // console.log(`key: ${key}`);
            if (key > 10000) {
              console.log("Should never get here. Don't console.log key value");
            }
            // console.log(`key: ${key}; value.hole_number: ${value.hole_number}`);
            // If data is entered then TOAST and user goes to UPDATE
            if (chosenHoleNumber.value == value.hole_number) {
              dataExists = true;
              toast.add({
                severity: "info",
                summary: "Information Message",
                detail: `Data already entered for HOLE ${value.hole_number}. Update from table if required`,
                life: 3000,
              });
              chosenHoleNumber.value = null;
              break;
            }
          }
          if (dataExists) {
            console.log("dataExists go to UPDATE");
          } else {
            newHoleDataEntry();
          }
        }
      } catch (err) {
        //console.log(`Error: EnterData selectedHole: ${err}`);
        router.push({
          name: "GeneralErr",
          params: {
            err: err,
            location: "EnterData.vue selectedHole",
          },
        });
      }
    }
    function newHoleDataEntry() {
      try {
        // console.log("In NEW HOLE data entry");
        prepareForNewHoleDataEntry();
        // Enter hole number and par
        hole_number.value = chosenHoleNumber.value;
        par.value = course.value["hole" + chosenHoleNumber.value];
        //ReSet hole dropdown to null if used
        chosenHoleNumber.value = null;
        enterProvisionalScores();
      } catch (err) {
        router.push({
          name: "GeneralErr",
          params: {
            err: err,
            location: "EnterData.vue newHoleDataEntry",
          },
        });
      }
    }
    function prepareForNewHoleDataEntry() {
      // Clear any data in hole_no, Par
      hole_number.value = null;
      par.value = null;
      // Close dataEntry widget if open
      dataEntryHideTimeLine();
      // Change heading if comming from Update
      holeDataEntryText.value = "NEW hole data";

      // Remove any highlights by setting all backgrounds to baseline
      gsap.set(".displayBox1", { backgroundColor: "#add8e6" });

      // Hide choices
      gsap.to(".choiceButton", { opacity: 0, duration: 0, display: "none" });
      // Remove the Prefilled value and set highlight
      clearEvaluations();
      // If "Update" buttton showing toggle the two save buttons
      if (updateDisplay.value == "display") {
        toggleSaveButtons();
      }
      // Show the blank new hole widget
      dataEntryShowTimeLine();
    }
    // Get roundData and course associated with round ID from dropdown
    async function selectedRoundId() {
      try {
        NProgress.start();
        // gsap.set(".loadingStatus", { visibility: "visible" });
        // const id = event.target.value;
        // console.log(`chosenRoundId: ${chosenRoundId.value}`);
        const id = chosenRoundId.value;
        // console.log(`In selectRoundId id: ${id}`);
        // await getRoundsById(id);
        await store.dispatch("roundData/getRoundsById", id);
        await store.dispatch("course/getCourseFromRoundId", id);
        course.value = store.state.course.course[0];
        createHoles();
        completedHoles();
      } catch (err) {
        console.log(`EnterData selectedRoundId error: ${err}`);
      } finally {
        NProgress.done();
        // gsap.set(".loadingStatus", { visibility: "hidden" });
      }
    }
    // Update Hole data into round_data Db
    async function updateRoundData() {
      try {
        NProgress.start();
        // gsap.set(".loadingStatus", { visibility: "visible" });
        const holeId = chosenHoleId.value;
        const holeData = {
          rounds_id: chosenRoundId.value,
          hole_number: hole_number.value,
          par: par.value,
          score: scoreEvaluation.value,
          driving: fairwayEvaluation.value,
          approach: greenEvaluation.value,
          putting: puttingEvaluation.value,
          scrambling: scramblingEvaluation.value,
          putt1_distance: parseFloat(putt1Distance.value),
          putt2_distance: parseFloat(putt2Distance.value),
        };

        await store.dispatch("roundData/updateRoundData", { holeId, holeData });
        // gsap.set(".loadingStatus", { visibility: "hidden" });
        // Display success message
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: `Successfully updated`,
          life: 2000,
        });
        // selectedRoundId() clears most of widget cleared but need to clear choices
        var array1 = document.getElementsByClassName("choiceButton");
        array1.forEach((element) => {
          element.classList.add("displayNone");
        });
        // Show next hole and par
        completedHoles();
      } catch (error) {
        // gsap.set(".loadingStatus", { visibility: "hidden" });
        // console.log(`Error EnterData.vue updateRoundData: ${error}`);
        router.push({
          name: "GeneralErr",
          params: {
            err: error,
            location: "EnterData.vue updateRoundData",
          },
        });
      } finally {
        NProgress.done();
      }
    }
    function useSelectedHoleData() {
      // Clear enterData widgit
      hole_number.value = null;
      par.value = null;
      clearEvaluations();
      // this.clearHighlights();
      this.hideChoices();
      // If "Save" showing toggle the two save buttons
      if (saveDisplay.value == "display") {
        toggleSaveButtons();
      }
      closeOpenDataEntry();
      async function closeOpenDataEntry() {
        await dataEntryHideTimeLine();
        // Change Heading of EnterData Widget
        holeDataEntryText.value = "CHANGE hole data";
        // Remove any highlights by setting all backgrounds to baseline
        gsap.set(".displayBox1", { backgroundColor: "#add8e6" });
        await dataEntryShowTimeLine();
        // Enter values from selectedRow
        chosenHoleId.value = selectedRow.value.id;
        hole_number.value = selectedRow.value.hole_number;
        par.value = selectedRow.value.par;
        scoreEvaluation.value = selectedRow.value.score;
        fairwayEvaluation.value = selectedRow.value.driving;
        greenEvaluation.value = selectedRow.value.approach;
        puttingEvaluation.value = selectedRow.value.putting;
        scramblingEvaluation.value = selectedRow.value.scrambling;
      }
    }
    function shotEvaluation(shot, evaluation) {
      switch (shot) {
        case "score":
          scoreEvaluation.value = evaluation;
          //console.log(`scoreEvaluation: ${scoreEvaluation.value}`);
          break;
        case "fairway":
          fairwayEvaluation.value = evaluation;
          //console.log(`fairwayEvaluation: ${fairwayEvaluation.value}`);
          break;
        case "green":
          greenEvaluation.value = evaluation;
          //console.log(`greenEvaluation: ${greenEvaluation.value}`);
          break;
        case "putting":
          puttingEvaluation.value = evaluation;
          break;
        case "puttDistance":
          if (puttDistanceChoice == 1) {
            putt1Distance.value = putt1Distance.value + evaluation;
          } else if (puttDistanceChoice == 2) {
            putt2Distance.value = putt2Distance.value + evaluation;
          }
          break;
        case "scrambling":
          scramblingEvaluation.value = evaluation;
          //console.log(`scramblingEvaluation: ${scramblingEvaluation.value}`);
          break;
        default:
          console.log(
            `EnterData.vue shotEvaluation error: ${shot} is not recognized`
          );
      }
    }
    // Clear all Evaluations
    function clearEvaluations() {
      scoreEvaluation.value = null;
      fairwayEvaluation.value = "";
      greenEvaluation.value = "";
      puttingEvaluation.value = null;
      scramblingEvaluation.value = "";
      putt1Distance.value = "";
      putt2Distance.value = "";
    }
    // ClearIndividualEvaluation
    function clearEvaluation(x) {
      // Remove any highlights by setting all backgrounds to baseline
      gsap.set(".displayBox1", { backgroundColor: "#add8e6" });
      switch (x) {
        case "score":
          scoreEvaluation.value = null;
          gsap.set(`#${x}`, { backgroundColor: "yellow" });
          break;
        case "fairway":
          fairwayEvaluation.value = null;
          gsap.set(`#${x}`, { backgroundColor: "yellow" });
          break;
        case "green":
          greenEvaluation.value = "";
          gsap.set(`#${x}`, { backgroundColor: "yellow" });
          break;
        case "putting":
          puttingEvaluation.value = null;
          gsap.set(`#${x}`, { backgroundColor: "yellow" });
          break;
        case "puttDistance":
          // Set the focus to putt1Distance and clear both puttDistance values
          puttDistanceFocus(1);
          break;
        case "scrambling":
          scramblingEvaluation.value = "";
          gsap.set(`#${x}`, { backgroundColor: "yellow" });
          break;
        default:
          console.log(`'${x}' is not recognized`);
      }
    }
    // (Dispatch) post round data to store
    async function postRoundData() {
      try {
        // console.log(`ChosenRoundId: ${chosenRoundId.value}`);
        NProgress.start();
        // gsap.set(".loadingStatus", { visibility: "visible" });
        const newRoundData = {
          rounds_id: chosenRoundId.value,
          hole_number: hole_number.value,
          par: par.value,
          score: scoreEvaluation.value,
          driving: fairwayEvaluation.value,
          approach: greenEvaluation.value,
          putting: puttingEvaluation.value,
          scrambling: scramblingEvaluation.value,
          putt1_distance: parseFloat(putt1Distance.value),
          putt2_distance: parseFloat(putt2Distance.value),
        };
        await store.dispatch("roundData/postRoundData", newRoundData);
        //Show next hole/par
        completedHoles();
      } catch (err) {
        router.push({
          name: "GeneralErr",
          params: {
            err: err,
            location: "EnterData.vue postRoundData",
          },
        });
      } finally {
        NProgress.done();
        // gsap.set(".loadingStatus", { visibility: "hidden" });
      }
    }
    // Go to GraphsOneOrMore after round status changed
    function goToGraphsOneOrMore() {
      router.push({ name: "GraphsOneOrMore" });
    }
    async function roundCompleted() {
      try {
        NProgress.start();
        const roundId = chosenRoundId.value;
        const roundData = {
          data_entered: true,
        };
        await store.dispatch("round/roundCompleted", { roundId, roundData });
        toast.add({
          severity: "info",
          summary: "Information Message",
          detail: `Round has been stored in DB as completed`,
          life: 2000,
        });
        setTimeout(goToGraphsOneOrMore, 2000);
      } catch (err) {
        router.push({
          name: "GeneralErr",
          params: {
            err: err,
            location: "EnterData.vue roundCompleted",
          },
        });
      } finally {
        NProgress.done();
      }
    }
    // Get information from completed holes
    function completedHoles() {
      // Show data inputs
      var i = completedRounds.value.length;
      // Clear any data in hole_no, Par
      hole_number.value = null;
      par.value = null;
      // Close dataEntry widget if open
      dataEntryHideTimeLine();
      // Change heading if comming from Update
      holeDataEntryText.value = "NEW hole data";

      // Remove any highlights by setting all backgrounds to baseline
      gsap.set(".displayBox1", { backgroundColor: "#add8e6" });

      // Hide choices
      gsap.to(".choiceButton", { opacity: 0, duration: 0, display: "none" });
      // Remove the Prefilled value and set highlight
      clearEvaluations();
      // If "Update" buttton showing toggle the two save buttons
      if (updateDisplay.value == "display") {
        toggleSaveButtons();
      }

      // console.log(`i: ${i}`);
      if (i > 17) {
        toast.add({
          severity: "info",
          summary: "Information Message",
          detail: `Data entered for 18 holes`,
          life: 2000,
        });
      } else {
        // dataEntryShowTimeLine();
        // enterHoleNumberAndPar(i);
        getNextNewHole();
        // enterProvisionalScores();
      }
    }
    function getNextNewHole() {
      try {
        var highestHoleNumber = 0;
        var currentHoleNumber = 0;
        for (const [key, value] of Object.entries(completedRounds.value)) {
          // console.log(`key: ${key}`);
          if (key > 10000) {
            console.log("Should never get here. Don't console.log key value");
          }
          currentHoleNumber = value.hole_number;
          if (currentHoleNumber > highestHoleNumber) {
            highestHoleNumber = currentHoleNumber;
          }
        }
        // If ALL 18 HOLES have data entered
        if (completedRounds.value.length == 18) {
          toast.add({
            severity: "info",
            summary: "Information Message",
            detail: `Data entered for 18 holes`,
            life: 2000,
          });
          // If Hole No 18 has data but some holes still empty
          // Need to go back to first hole without data
        } else if (highestHoleNumber == 18) {
          var i;
          var holesCompleted = [];
          var holesToDo = [];
          var nextHole = null;
          const eighteenHoleArr = [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
          ];
          for (i = 0; i < completedRounds.value.length; i++) {
            // console.log(`IN LOOP: i: ${i}`);
            // console.log(
            //   `completedRounds.value[i].hole_number: ${completedRounds.value[i].hole_number}`
            // );
            holesCompleted.push(completedRounds.value[i].hole_number);
            // console.log(holesCompleted);
            holesToDo = eighteenHoleArr.filter(function (el) {
              return holesCompleted.indexOf(el) < 0;
            });
            // console.log(`holesToDo: ${holesToDo}`);
            nextHole = holesToDo[0];
          }
          // console.log(`nextHole: ${nextHole}`);
          dataEntryShowTimeLine();
          hole_number.value = nextHole;
          par.value = course.value["hole" + nextHole];
          enterProvisionalScores();

          // Entering data up to 18th hole
        } else {
          dataEntryShowTimeLine();
          hole_number.value = highestHoleNumber + 1;
          par.value = course.value["hole" + (highestHoleNumber + 1)];
          enterProvisionalScores();
        }
      } catch (err) {
        router.push({
          name: "GeneralErr",
          params: {
            err: err,
            location: "EnterData.vue getNextNewHole",
          },
        });
      }
    }
    function enterProvisionalScores() {
      // Enter provisional evaluations
      scoreEvaluation.value = par.value;
      if (par.value !== 3) {
        fairwayEvaluation.value = "H";
      }
      greenEvaluation.value = "H";
      puttingEvaluation.value = 2;
    }
    // Toggle buttons "Save" and "Update"
    function toggleSaveButtons() {
      if (saveDisplay.value == "display") {
        saveDisplay.value = "displayNone";
      } else {
        saveDisplay.value = "display";
      }
      if (updateDisplay.value == "display") {
        updateDisplay.value = "displayNone";
      } else {
        updateDisplay.value = "display";
      }
    }
    //********* Transitions and Animations ***********
    function dataEntryShowTimeLine() {
      const dataEntry = gsap.timeline();

      dataEntry.to("#allDataEntry", {
        display: "block",
        opacity: 1,
        height: "2.5rem",
        duration: 0.3,
        ease: "none",
      });
      dataEntry.to(".upperDataEntry", {
        opacity: 1,
        height: "9em",
        duration: 0.3,
        ease: "none",
      });
      dataEntry.set("#buttonBox", {
        opacity: 1,
        height: "4.5rem",
        // duration: 0.3,
        // ease: "power2",
        // onComplete: callOut,
      });
      return dataEntry;
    }
    function showUpperDataEntry() {
      const dataEntry = dataEntryShowTimeLine();
      dataEntry.play();
    }
    function dataEntryHideTimeLine() {
      const dataEntry = gsap.timeline();
      dataEntry.to("#buttonBox", {
        opacity: 0,
        height: "0rem",
        duration: 0.1,
        ease: "none",
      });
      dataEntry.to(".upperDataEntry", {
        opacity: 0,
        height: "0em",
        duration: 0.1,
      });
      dataEntry.to("#allDataEntry", {
        display: "none",
        opacity: 0,
        height: "0rem",
        duration: 0.1,
        ease: "none",
      });
      return dataEntry;
    }
    // Function using data from table slotProps (data)
    const scoreColor = (data) => {
      return [
        {
          eaglePlus: data.score < data.par - 1,
          birdie: data.score == data.par - 1,
          bogie: data.score == data.par + 1,
          doublePlus: data.score > data.par + 1,
        },
      ];
    };
    // END       ***** GET AND MANIPULATE DATA  *****

    return {
      // BEGIN ****** GET INFORMATION   ***********
      rounds,
      // END ****** GET INFORMATION   ***********

      // BEGIN ****** DYNAMIC INTERFACE  *********
      iconEye,
      displaySelections,
      toggleSelections,
      toggleInputBackgrounds,
      hideChoices,
      showChoice,
      addHighlight,
      removeHighlight,
      // END    ****** DYNAMIC INTERFACE  *********

      // BEGIN   ***** GET AND MANIPULATE DATA  *****
      roundCompleted,
      chosenHoleNumber,
      holes,
      createHoles,
      selectedHole,
      scoreColor,
      quickScores,
      selectedRoundId,
      postRoundData,
      puttingEvaluation,
      putt1Distance,
      putt2Distance,
      puttDistanceFocus,
      putt1DistanceBC,
      putt2DistanceBC,
      steps,
      shotEvaluation,
      scoreEvaluation,
      fairwayEvaluation,
      greenEvaluation,
      scramblingEvaluation,
      completedRounds,
      course,
      par,
      hole_number,
      completedHoles,
      chosenRoundId,
      clearEvaluations,
      selectedRow,
      useSelectedHoleData,
      holeDataEntryText,
      chosenHoleId,
      updateRoundData,
      clearEvaluation,
      updateDisplay,
      saveDisplay,
      toggleSaveButtons,
      //transitions animations
      showUpperDataEntry,
      dataEntryHideTimeLine,
      // END     ***** GET AND MANIPULATE DATA  *****
    };
  },
};
</script>

<style lang="scss" scoped>
.custom {
  .p-dropdown {
    width: 7rem;
  }
  ::v-deep(.p-inputtext) {
    font-size: 1.7rem;
    font-weight: bold;
  }
}
.custom2 {
  .p-dropdown {
    width: 7rem;
  }
  ::v-deep() {
    .p-inputtext {
      font-size: 1.7rem;
      font-weight: bold;
    }
    .p-component {
      //background-color: red;
      font-size: 1.7rem;
    }
    ::v-deep() {
      .p-inputtext {
        font-size: 1.7rem;
        font-weight: bold;
      }
      .p-component {
        background-color: red;
        font-size: 1.7rem;
      }
      ::v-deep() {
        .p-inputtext {
          font-size: 1.7rem;
          font-weight: bold;
        }
        .p-component {
          background-color: red;
          font-size: 1.7rem;
        }
      }
    }
  }
}

// Styles the text in the "Table of Completed Holes"
::v-deep(.p-component) {
  font-size: 1.5rem;
  // font-weight: bold;
  //background-color: red;
}

.eaglePlus {
  color: white;
  font-weight: bold;
  display: block;
  height: 36px;
  width: 36px;
  line-height: 36px;
  border-radius: 18px;
  background-color: green;
  margin-left: 1em;
}
.birdie {
  color: white;
  font-weight: bold;
  display: block;
  height: 36px;
  width: 36px;
  line-height: 36px;
  border-radius: 18px;
  background-color: red;
  margin-left: 1em;
}
.bogie {
  color: white;
  font-weight: bold;
  display: block;
  height: 32px;
  width: 32px;
  line-height: 32px;
  // border-radius: 18px;
  background-color: blue;
  margin-left: 1.1em;
}
.doublePlus {
  color: white;
  font-weight: bold;
  display: block;
  height: 32px;
  width: 32px;
  line-height: 32px;
  // border-radius: 18px;
  background-color: black;
  margin-left: 1.1em;
}
.max-width {
  max-width: 800px;
  margin: 0 auto 0 auto;
}
.maxWidth2 {
  max-width: 600px;
}
.dataEntryHeader {
  background-color: var(--surface-100);
  border-top: solid 1px var(--bluegray-100);
  border-left: solid 1px var(--bluegray-100);
  border-right: solid 1px var(--bluegray-100);
}
.allDataEntry {
  display: none;
}
.displayNone {
  display: none;
}
// .loadingStatus {
//   visibility: hidden;
// }
.border {
  border: solid 1px var(--bluegray-100);
  background-color: var(--surface-100);
}
.displayBox1 {
  border: solid 2px black;
  background-color: #add8e6;
  height: 2.2rem;
  width: 3.25rem;
  text-align: center;
  font-weight: bold;
  font-size: 22px;
}
.upperDataEntry {
  opacity: 0;
  height: 0em;
  background-color: var(--bluegray-200);
}
.buttonBox1 {
  opacity: 0;
  height: 0em;
  background-color: var(--bluegray-200);
}
.bSize1 {
  font-size: 24px;
  font-weight: bold;
}
.fontLarge {
  font-size: large;
}
.minHeight {
  min-height: 35rem;
}
.par3 {
  color: green;
}
.par5 {
  color: blue;
}
.backgroundGrey {
  background-color: var(--bluegray-200);
}
.divider {
  border-top: solid 1px var(--bluegray-500);
}
.ta-center {
  text-align: center;
}

.input {
  text-align: center;
  width: 2em;
  // margin: 0 0.25em 0 0.25em;
}
.driving {
  margin: 1em 1em 1em 1em;
}
select {
  font-size: large;
  margin: 0 0 1em 0;
}
// BUTTON STYLING
.largeFontSpacing {
  font-size: 24px;
  font-weight: bold;
  margin: 1.5rem 0 1.5rem 0;
}
.puttDistanceSpacing {
  font-size: 24px;
  font-weight: bold;
  margin: 0.7rem 0 0.7rem 0;
}
.puttDistanceInput {
  width: 5rem;
}
.yellowBG {
  background-color: yellow;
}
.whiteBG {
  background-color: white;
}
input {
  border: solid 1px;
  font-weight: bold;
  color: black;
  background-color: rgb(248, 246, 246);
}
.choiceSpacing {
  margin: 0 0 0.75em 0.25em;
  font-size: large;
}
.puttingSpacing {
  margin: 1em 1em 1em 1em;
  font-size: large;
  background-color: #ffff00;
}
.scramblingSpacing {
  margin: 1em 0.5em 1em 0.5em;
  font-size: large;
  background-color: #ffff00;
}
</style>
