import EventService from "@/services/EventService.js";

export const namespaced = true;

export const state = {
  clubs: [], // Array of all club objects
};

export const mutations = {
  SET_CLUBS(state, clubs) {
    state.clubs = clubs;
  },
};

export const actions = {
  async getClubs({ commit }) {
    try {
      const res = await EventService.getClubs();
      const clubs = res.data;
      // console.log(`course.js res.data: ${res.data[0].course}`);
      commit("SET_CLUBS", clubs); // only if await promise is resolved not rejected
      console.log(`Status: club.js getClubs status: ${res.status})`);
    } catch (err) {
      console.log(`Error: club.js getClubs: ${err}`);
    }
  },
};

export const getters = {};
