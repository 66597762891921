import EventService from "@/services/EventService.js";

export const namespaced = true;

export const state = {
  allInOrCompleteRounds: [],
  postRound: {}, // Round data that was posted NOT FROM A RESPONSE
  roundCompleted: {},
};

export const mutations = {
  SET_IN_OR_COMPLETE_ROUNDS(state, rounds) {
    state.allInOrCompleteRounds = rounds;
  },
  SET_POST_ROUND(state, round) {
    state.postRound = round;
  },
  SET_ROUND_COMPLETED(state, roundData) {
    state.roundCompleted = roundData;
  },
  CLEAR_ALL_DATA(state) {
    state.AllRounds = {};
    state.postRound = {};
    state.roundId = "";
    state.roundCompleted = false;
  },
};

export const actions = {
  async getInOrCompleteRoundsById({ commit }, { id, data_entered }) {
    try {
      const res = await EventService.getInOrCompleteRoundsById(
        id,
        data_entered
      );
      const rounds = res.data;
      // console.log(`round.js rounds: ${rounds}`);
      commit("SET_IN_OR_COMPLETE_ROUNDS", rounds);
      console.log(
        `All rounds in store: round.js getAllRounds res.status: ${res.status}`
      );
    } catch (err) {
      console.log(`Error: round getAllRounds: ${err}`);
    }
  },

  async postRound({ commit }, round) {
    try {
      const response = await EventService.postRound(round);
      commit("SET_POST_ROUND", round); // only if await promise is resolved not rejected
      console.log(`round.js response: ${response.status}`);
    } catch (err) {
      console.log(`round.js postRound error: ${err}`);
    }
  },
  async roundCompleted({ commit }, { roundId, roundData }) {
    try {
      const response = await EventService.roundCompleted(roundId, roundData);
      commit("SET_ROUND_COMPLETED", roundData); // only if await promise is resolved not rejected
      console.log(`round.js response: ${response.status}`);
    } catch (err) {
      console.log(`Error: round.js roundCompleted: ${err}`);
    }
  },
};

export const getters = {};
