<template>
  <div class="p-grid">
    <!-- <div class="p-col-12">
      <Button label="Show All Graphs" @click="showGraphs" class="p-mt-3" />
    </div> -->
    <h1 class="p-col-12">Scoring: Single Player</h1>
    <div class="p-col-12 p-d-flex p-mb-4 p-jc-around p-ai-stretch">
      <Card class="p-col-12 p-md-10">
        <template #title> ball striking, scrambling, putting </template>
        <template #content>
          <div>
            <Chart type="bar" :data="basicData" :options="basicOptions" />
            <!-- <Chart type="bar" :data="bar2" /> -->
          </div>
        </template>
      </Card>
    </div>
    <div class="p-col-12 p-d-flex p-jc-center p-mb-4">
      <Card class="p-col-12 p-md-8">
        <template #title> hole scores </template>
        <template #content>
          <div>
            <!-- <Chart type="bar" :data="basicData" /> -->
            <Chart type="bar" :data="bar2" :options="bar2Options" />
          </div>
        </template>
      </Card>
    </div>
    <div class="p-grid p-jc-center p-mb-4 p-ai-stretch">
      <Card class="p-col-10 p-md-4">
        <template #title> % fairway misses </template>
        <template #content>
          <div>
            <Chart type="bar" :data="drivingBar" :options="drivingBarOptions" />
          </div>
        </template>
      </Card>
      <Card class="p-col-10 p-md-8">
        <template #title> direction of fairway miss </template>
        <template #content>
          <div>
            <Chart type="pie" :data="drivingPie" :options="drivingPieOptions" />
          </div>
        </template>
      </Card>
    </div>
    <div class="p-grid p-jc-center p-mb-4 p-ai-stretch">
      <Card class="p-col-10 p-md-4">
        <template #title> % greens missed </template>
        <template #content>
          <div>
            <Chart
              type="bar"
              :data="approachBar"
              :options="approachBarOptions"
            />
          </div>
        </template>
      </Card>
      <Card class="p-col-10 p-md-8">
        <template #title> direction of green misses </template>
        <template #content>
          <div>
            <Chart
              type="pie"
              :data="approachPie"
              :options="approachPieOptions"
            />
          </div>
        </template>
      </Card>
    </div>
    <div class="p-grid p-jc-center p-mb-4 p-ai-stretch">
      <Card class="p-col-10 p-md-4">
        <template #title> overall scrambling </template>
        <template #content>
          <div>
            <Chart
              type="bar"
              :data="scramblingBar"
              :options="scramblingBarOptions"
            />
          </div>
        </template>
      </Card>
      <Card class="p-col-10 p-md-8">
        <template #title> scrambling from fairway, rough and bunker </template>
        <template #content>
          <div>
            <Chart
              type="bar"
              :data="scramblingBar2"
              :options="scramblingBar2Options"
            />
          </div>
        </template>
      </Card>
    </div>
    <div class="p-grid p-jc-center p-mb-4 p-ai-stretch">
      <Card class="p-col-10 p-md-4">
        <template #title> overall putting </template>
        <template #content>
          <div>
            <Chart type="bar" :data="puttingBar" :options="puttingBarOptions" />
          </div>
        </template>
      </Card>
      <Card class="p-col-10 p-md-8">
        <template #title> putting per green, per round </template>
        <template #content>
          <div>
            <Chart
              type="bar"
              :data="puttingBar2"
              :options="puttingBar2Options"
            />
          </div>
        </template>
      </Card>
    </div>
    <div class="p-grid p-jc-center p-mb-4 p-ai-stretch">
      <Card class="p-col-10 p-md-6">
        <template #title> ave putt 1 distances </template>
        <template #content>
          <div>
            <Chart
              type="bar"
              :data="puttDistBar"
              :options="puttDistBarOptions"
            />
          </div>
        </template>
      </Card>
      <Card class="p-col-10 p-md-6">
        <template #title> ave putt 1 dist scrambling </template>
        <template #content>
          <div>
            <Chart
              type="bar"
              :data="puttDistBar2"
              :options="puttDistBar2Options"
            />
          </div>
        </template>
      </Card>
    </div>
    <div class="p-col-12 p-grid p-jc-center p-mb-4 p-ai-stretch">
      <Card class="p-col-10 p-md-8">
        <template #title> ave putt 1,2,3 distances </template>
        <template #content>
          <div>
            <Chart
              type="bar"
              :data="puttDistBar3"
              :options="puttDistBar3Options"
            />
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import store from "../store";
import router from "../router";
import NProgress from "nprogress";
export default {
  name: "GraphsAllData",
  setup() {
    onMounted(async () => {
      try {
        NProgress.start();
        const id = store.state.player.player[0].playerId;
        const p1 = new Promise((resolve) => {
          resolve(store.dispatch("playerData/getBasicScores", id));
        });
        const p2 = new Promise((resolve) => {
          resolve(store.dispatch("playerData/getDrivingStats", id));
        });
        const p3 = new Promise((resolve) => {
          resolve(store.dispatch("playerData/getApproachStats", id));
        });
        const p4 = new Promise((resolve) => {
          resolve(store.dispatch("playerData/getPuttingStats", id));
        });
        const p5 = new Promise((resolve) => {
          resolve(store.dispatch("playerData/getScramblingStats", id));
        });
        Promise.allSettled([p1, p2, p3, p4, p5])
          .then((values) => {
            // console.log(`values[0].status: ${values[0].status}`);
            if (values[0].status == "fulfilled") {
              showGraphs();
            }
          })
          .catch((err) => {
            router.push({
              name: "GeneralErr",
              params: {
                err: err,
                location: "GraphsAllData.vue selectedRoundIds",
              },
            });
          });
      } catch (err) {
        router.push({
          name: "GeneralErr",
          params: {
            err: err,
            location: "GraphsAllData.vue selectedRoundIds",
          },
        });
      } finally {
        NProgress.done();
        // gsap.set(".loadingStatus", { visibility: "hidden" });
      }
    });
    var basicData = ref({});
    var basicOptions = ref({});
    var bar2 = ref({});
    var bar2Options = ref({});
    var drivingBar = ref({});
    var drivingBarOptions = ref({});
    var drivingPie = ref({});
    var drivingPieOptions = ref({});
    var approachBar = ref({});
    var approachBarOptions = ref({});
    var approachPie = ref({});
    var approachPieOptions = ref({});
    var scramblingBar = ref({});
    var scramblingBarOptions = ref({});
    var scramblingBar2 = ref({});
    var scramblingBar2Options = ref({});
    var puttingBar = ref({});
    var puttingBarOptions = ref({});
    var puttingBar2 = ref({});
    var puttingBar2Options = ref({});
    var puttDistBar = ref({});
    var puttDistBarOptions = ref({});
    var puttDistBar2 = ref({});
    var puttDistBar2Options = ref({});
    var puttDistBar3 = ref({});
    var puttDistBar3Options = ref({});
    // All aggregate data for a round
    const basicScores = computed(() => {
      return store.state.playerData.basicScores;
    });
    const drivingStats = computed(() => {
      return store.state.playerData.drivingStats;
    });
    const approachStats = computed(() => {
      return store.state.playerData.approachStats;
    });
    const puttingStats = computed(() => {
      return store.state.playerData.puttingStats;
    });
    const scramblingStats = computed(() => {
      return store.state.playerData.scramblingStats;
    });

    function showGraphs() {
      try {
        barGraph1();
        barGraph2();
        drivingBarGraph();
        drivingPieGraph();
        approachBarGraph();
        approachPieGraph();
        scramblingBarGraph();
        scramblingBar2Graph();
        puttingBarGraph();
        puttingBar2Graph();
        puttDistBarGraph();
        puttDistBar2Graph();
        puttDistBar3Graph();
      } catch (err) {
        console.log(`Error GraphsAllData.vue showGraphs: ${err}`);
        router.push({
          name: "GeneralErr",
          params: {
            err: err,
            location: "GraphsAllData.vue showGraphs",
          },
        });
      }
    }
    function barGraph1() {
      var totalRounds = basicScores.value[0].rounds_tot;
      var label1 = [
        "ave score",
        "% fairways hit",
        "% gir",
        "ave putts",
        "% u&d",
      ];
      var data1 = [
        basicScores.value[0].score_pr,
        drivingStats.value[0].fairways_hit_pct,
        approachStats.value[0].greens_hit_pct,
        puttingStats.value[0].ave_putts_pr,
        scramblingStats.value[0].ud_yes_pct,
      ];
      basicData.value = {
        // labels: Object.keys(roundAggregateData.value[0]),
        labels: label1,
        datasets: [
          {
            label: `Over ${totalRounds} rounds`,
            backgroundColor: [
              "#42A5F5",
              "#42A5F5",
              "#42A5F5",
              "#42A5F5",
              "#42A5F5",
            ],
            //data: Object.values(roundAggregateData.value[0]),
            data: data1,
          },
        ],
      };
      basicOptions.value = {
        scales: {
          yAxes: [
            {
              ticks: {
                min: 20,
                max: 80,
              },
            },
          ],
        },
      };
    }
    function barGraph2() {
      var label = [
        "eargles_pr",
        "birdies_pr",
        "pars_pr",
        "bogies_pr",
        "doubles_pr",
        "others_pr",
      ];
      var data = [
        basicScores.value[0].eagles_pre,
        basicScores.value[0].birdies_pr,
        basicScores.value[0].pars_pr,
        basicScores.value[0].bogies_pr,
        basicScores.value[0].doubles_pr,
        basicScores.value[0].others_pr,
      ];
      bar2.value = {
        labels: label,
        datasets: [
          {
            label: "All Rounds",
            backgroundColor: "#42A5F5",
            hoverBackgroundColor: ["#64B5F6"],
            data: data,
          },
        ],
      };
      bar2Options.value = {
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
              },
            },
          ],
        },
      };
    }
    function drivingBarGraph() {
      var label = ["% fairways missed"];
      var data = [drivingStats.value[0].fairways_missed_pct];
      drivingBar.value = {
        // labels: Object.keys(roundAggregateData.value[0]),
        labels: label,
        datasets: [
          {
            label: "Per Round",
            backgroundColor: [
              "#42A5F5",
              "#EC407A",
              "#AB47BC",
              "#42A5F5",
              "#7E57C2",
            ],
            //data: Object.values(roundAggregateData.value[0]),
            data: data,
          },
        ],
      };
      drivingBarOptions.value = {
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                // max: 50,
              },
            },
          ],
        },
      };
    }
    function drivingPieGraph() {
      var label = ["% Rt", "% Lt", "% Long", "% Short"];
      var data = [
        drivingStats.value[0].fairways_Rt_pct,
        drivingStats.value[0].fairways_Lt_pct,
        drivingStats.value[0].fairways_L_pct,
        drivingStats.value[0].fairways_S_pct,
      ];

      drivingPie.value = {
        labels: label,
        datasets: [
          {
            data: data,
            backgroundColor: ["#42A5F5", "#66BB6A", "#FFA726", "#FF6384"],
            hoverBackgroundColor: ["#64B5F6", "#81C784", "#FFB74D", "#FF6384"],
          },
        ],
      };
    }
    function approachBarGraph() {
      var label = ["% greens missed"];
      var data = [approachStats.value[0].greens_missed_pct];
      approachBar.value = {
        // labels: Object.keys(roundAggregateData.value[0]),
        labels: label,
        datasets: [
          {
            label: "Per Round",
            backgroundColor: [
              "#42A5F5",
              "#EC407A",
              "#AB47BC",
              "#42A5F5",
              "#7E57C2",
            ],
            //data: Object.values(roundAggregateData.value[0]),
            data: data,
          },
        ],
      };
      approachBarOptions.value = {
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                // max: 50,
              },
            },
          ],
        },
      };
    }
    function approachPieGraph() {
      var label = [
        // "% miss ShortLt",
        // "% miss ShortRt",
        // "% miss LongLt",
        // "% miss LongRt",
        "% Short",
        "% Lt",
        "% Rt",
        "% Long",
      ];
      var data = [
        // approachStats.value[0].greens_Slt_pct,
        // approachStats.value[0].greens_Srt_pct,
        // approachStats.value[0].greens_Llt_pct,
        // approachStats.value[0].greens_Lrt_pct,
        approachStats.value[0].greens_S_pct,
        approachStats.value[0].greens_Lt_pct,
        approachStats.value[0].greens_Rt_pct,
        approachStats.value[0].greens_L_pct,
      ];

      approachPie.value = {
        labels: label,
        datasets: [
          {
            data: data,
            backgroundColor: [
              "#42A5F5",
              "#66BB6A",
              "#FFA726",
              "#FF6384",
              // "#EC407A",
              // "#AB47BC",
              // "#42A5F5",
            ],
            hoverBackgroundColor: ["#64B5F6", "#81C784", "#FFB74D", "#FF6384"],
          },
        ],
      };
    }
    function scramblingBarGraph() {
      var label = ["% scrambling made"];
      var data = [scramblingStats.value[0].ud_yes_pct];
      scramblingBar.value = {
        // labels: Object.keys(roundAggregateData.value[0]),
        labels: label,
        datasets: [
          {
            label: "Per Round",
            backgroundColor: [
              "#42A5F5",
              "#EC407A",
              "#AB47BC",
              "#42A5F5",
              "#7E57C2",
            ],
            //data: Object.values(roundAggregateData.value[0]),
            data: data,
          },
        ],
      };
      scramblingBarOptions.value = {
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                // max: 50,
              },
            },
          ],
        },
      };
    }
    function scramblingBar2Graph() {
      var label = ["% fairway made", "% rough made", "% bunker made"];
      var data = [
        scramblingStats.value[0].ud_fy_pct,
        scramblingStats.value[0].ud_ry_pct,
        scramblingStats.value[0].ud_by_pct,
      ];
      scramblingBar2.value = {
        // labels: Object.keys(roundAggregateData.value[0]),
        labels: label,
        datasets: [
          {
            label: "of total",
            backgroundColor: ["#42A5F5", "#42A5F5", "#42A5F5"],
            //data: Object.values(roundAggregateData.value[0]),
            data: data,
          },
        ],
      };
      scramblingBar2Options.value = {
        // maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                // max: 50,
              },
            },
          ],
        },
      };
    }
    function puttingBarGraph() {
      var label = ["Ave Putts Per Round"];
      var data = [puttingStats.value[0].ave_putts_pr];
      // var data = [puttingStats.value[0].avg_putt1_distance_b];
      puttingBar.value = {
        // labels: Object.keys(roundAggregateData.value[0]),
        labels: label,
        datasets: [
          {
            label: "Per Round",
            backgroundColor: [
              "#42A5F5",
              "#EC407A",
              "#AB47BC",
              "#42A5F5",
              "#7E57C2",
            ],
            //data: Object.values(roundAggregateData.value[0]),
            data: data,
          },
        ],
      };
      puttingBarOptions.value = {
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                // max: 50,
              },
            },
          ],
        },
      };
    }
    function puttingBar2Graph() {
      var label = [
        "putts per green",
        "putts per GIR",
        "zero putts",
        "one putts",
        "three putts",
        "others",
      ];
      var data = [
        puttingStats.value[0].ave_putts_pg,
        puttingStats.value[0].avg_putts_gir,
        puttingStats.value[0].zero_putts_pr,
        puttingStats.value[0].one_putts_pr,
        puttingStats.value[0].three_putts_pr,
        puttingStats.value[0].other_putts_pr,
      ];
      puttingBar2.value = {
        // labels: Object.keys(roundAggregateData.value[0]),
        labels: label,
        datasets: [
          {
            label: "per green and per round",
            backgroundColor: [
              "#66BB6A",
              "#66BB6A",
              "#42A5F5",
              "#42A5F5",
              "#42A5F5",
              "#42A5F5",
            ],
            //data: Object.values(roundAggregateData.value[0]),
            data: data,
          },
        ],
      };
      puttingBar2Options.value = {
        // maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                // max: 50,
              },
            },
          ],
        },
      };
    }
    function puttDistBarGraph() {
      var label = ["all (per green)", "approach (gir)", "scrambling"];
      var data = [
        puttingStats.value[0].avg_putt1_distance_gr,
        puttingStats.value[0].avg_putt1_distance_gir,
        puttingStats.value[0].avg_putt1_distance_s,
      ];
      puttDistBar.value = {
        // labels: Object.keys(roundAggregateData.value[0]),
        labels: label,
        datasets: [
          {
            label: "dist to hole",
            backgroundColor: [
              "#42A5F5",
              "#EC407A",
              "#AB47BC",
              "#42A5F5",
              "#7E57C2",
            ],
            //data: Object.values(roundAggregateData.value[0]),
            data: data,
          },
        ],
      };
      puttDistBarOptions.value = {
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                // max: 50,
              },
            },
          ],
        },
      };
    }
    function puttDistBar2Graph() {
      var label = ["from fairway", "from bunker", "from rough"];
      var data = [
        puttingStats.value[0].avg_putt1_distance_f,
        puttingStats.value[0].avg_putt1_distance_b,
        puttingStats.value[0].avg_putt1_distance_r,
      ];
      puttDistBar2.value = {
        // labels: Object.keys(roundAggregateData.value[0]),
        labels: label,
        datasets: [
          {
            label: "scrambling",
            backgroundColor: [
              "#66BB6A",
              "#66BB6A",
              "#42A5F5",
              "#42A5F5",
              "#42A5F5",
              "#42A5F5",
            ],
            //data: Object.values(roundAggregateData.value[0]),
            data: data,
          },
        ],
      };
      puttDistBar2Options.value = {
        // maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                // max: 50,
              },
            },
          ],
        },
      };
    }
    function puttDistBar3Graph() {
      var label = [
        "putt1 per green",
        "putt1 (gir)",
        "putt2 per green",
        "putt2 gir",
      ];
      var data = [
        puttingStats.value[0].avg_putt1_distance_gr,
        puttingStats.value[0].avg_putt1_distance_gir,
        puttingStats.value[0].avg_putt2_distance_gr,
        puttingStats.value[0].avg_putt2_distance_gir,
      ];
      puttDistBar3.value = {
        // labels: Object.keys(roundAggregateData.value[0]),
        labels: label,
        datasets: [
          {
            label: "dist to hole",
            backgroundColor: [
              "#42A5F5",
              "#EC407A",
              "#AB47BC",
              "#42A5F5",
              "#7E57C2",
            ],
            //data: Object.values(roundAggregateData.value[0]),
            data: data,
          },
        ],
      };
      puttDistBar3Options.value = {
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                // max: 50,
              },
            },
          ],
        },
      };
    }

    return {
      puttingStats,
      basicScores,
      showGraphs,
      basicData,
      basicOptions,
      bar2,
      bar2Options,
      drivingBar,
      drivingBarOptions,
      drivingPie,
      drivingPieOptions,
      approachBar,
      approachBarOptions,
      approachPie,
      approachPieOptions,
      scramblingBar,
      scramblingBarOptions,
      scramblingBar2,
      scramblingBar2Options,
      puttingBar,
      puttingBarOptions,
      puttingBar2,
      puttingBar2Options,
      puttDistBar,
      puttDistBarOptions,
      puttDistBar2,
      puttDistBar2Options,
      puttDistBar3,
      puttDistBar3Options,
    };
  },
};
</script>
