<template>
  <!-- <div class="p-mt-2">
    <ProgressSpinner
      class="loadingStatus"
      style="width: 30px; height: 30px"
      strokeWidth="8"
      animationDuration="1s"
    />
  </div> -->
  <div class="p-fluid max-width">
    <Card>
      <template #title>Create a New Round</template>
      <template #content>
        <div>
          <form @submit.prevent="postRound">
            <div class="p-field">
              <label for="courseDropdown">Courses</label>
              <Dropdown
                id="courseDropdown"
                v-model="chosenCourseId"
                :options="courses"
                optionValue="id"
                dataKey="id"
                optionLabel="name"
                placeholder="Select a course"
              />
            </div>
            <div class="p-field">
              <label for="handicapDropdown">Course Handicaps</label>
              <Dropdown
                id="handicapDropdown"
                v-model="chosenHandicap"
                :options="courseHandicaps"
                optionValue="handicap"
                dataKey="handicap"
                optionLabel="handicap"
                placeholder="Select course handicap"
              />
            </div>
            <div class="p-field">
              <label for="calendar">Select a Date</label>
              <Calendar id="calendar" v-model="picked" dateFormat="mm-dd-yy" />
            </div>
            <Button label="Submit" @click="postRound()" />
          </form>
        </div>
      </template>
    </Card>
  </div>
  <div><Toast position="center" /></div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import store from "../store";
import { useToast } from "primevue/usetoast";
import NProgress from "nprogress";
export default {
  name: "CreateRound",
  components: {
    // Datepicker,
  },
  setup() {
    const toast = useToast();
    const courseHandicaps = ref([
      { handicap: -5 },
      { handicap: -4 },
      { handicap: -3 },
      { handicap: -2 },
      { handicap: -1 },
      { handicap: 0 },
      { handicap: 1 },
      { handicap: 2 },
      { handicap: 3 },
      { handicap: 4 },
      { handicap: 5 },
      { handicap: 6 },
      { handicap: 7 },
      { handicap: 8 },
      { handicap: 9 },
      { handicap: 10 },
    ]);
    const players = computed(() => {
      return store.state.player.AllPlayers;
    });
    const rounds = computed(() => {
      return store.state.round.allRounds;
    });
    const courses = computed(() => {
      return store.state.course.allCourses;
    });
    const picked = ref(new Date());
    const chosenCourseId = ref(null);
    const chosenHandicap = ref("");
    var round = {
      players_id: null,
      courses_id: null,
      date: "",
      course_handicap: null,
      data_entered: false,
    };
    // Dispatch function to Store
    async function postRound() {
      try {
        NProgress.start();
        round.players_id = store.state.player.player[0].playerId;
        round.courses_id = chosenCourseId.value;
        round.date = picked.value;
        round.course_handicap = chosenHandicap.value;
        round.data_entered = false;
        await store.dispatch("round/postRound", round);
        clearRound();
        console.log(
          `From store.state.round.postRound.date: ${store.state.round.postRound.date}`
        );
        toast.add({
          severity: "success",
          summary: "Success Message",
          detail: `New round created`,
          life: 2000,
        });
      } catch (error) {
        console.log(`Error: CreateRound.vue postRound(): ${error}`);
        toast.add({
          severity: "error",
          summary: "Error Message",
          detail: `Error: ${error}`,
          life: 2000,
        });
      } finally {
        NProgress.done();
      }
    }
    function clearRound() {
      round = {
        players_id: null,
        courses_id: null,
        date: "",
        course_handicap: null,
      };
    }
    // Get players for select when componenet mounts
    onMounted(() => {
      try {
        // Get all players and place them in store
        store.dispatch("player/getAllPlayers");
        // Get all courses and place them in store
        store.dispatch("course/getAllCourses");
      } catch (err) {
        console.log(`Home.vue EventService.getAllPlayers err: ${err}`);
      }
    });
    return {
      players,
      rounds,
      courses,
      picked,
      postRound,
      clearRound,
      courseHandicaps,
      chosenHandicap,
      chosenCourseId,
    };
  },
};
</script>

<style lang="scss" scoped>
.max-width {
  max-width: 250px;
  margin: 0 auto 0 auto;
}
.loadingStatus {
  visibility: hidden;
}
</style>
