import EventService from "@/services/EventService.js";

export const namespaced = true;

export const state = {
  allCourses: [], // Array of all player objects
  course: {},
};

export const mutations = {
  SET_ALL_COURSES(state, courses) {
    state.allCourses = courses;
  },
  SET_COURSE(state, course) {
    state.course = course;
  },
  CLEAR_ALL_DATA(state) {
    state.allCourses = {};
  },
};

export const actions = {
  async getAllCourses({ commit }) {
    try {
      const res = await EventService.getAllCourses();
      const courses = res.data;
      // console.log(`course.js res.data: ${res.data[0].course}`);
      commit("SET_ALL_COURSES", courses); // only if await promise is resolved not rejected
      console.log(
        `All courses in store. (course.js res.status: ${res.status})`
      );
    } catch (err) {
      console.log(`course.js getAllCourses error: ${err}`);
    }
  },
  async getCourseFromRoundId({ commit }, id) {
    try {
      // console.log(`In course.js id: ${id}`)
      const res = await EventService.getCourseFromRoundId(id);
      const course = res.data;
      // console.log(`course.js res.data: ${res.data[0].course}`);
      commit("SET_COURSE", course); // only if await promise is resolved not rejected
      console.log(`Course in store. (course.js res.status: ${res.status})`);
    } catch (err) {
      console.log(`course.js getCourseFromRoundId error: ${err}`);
    }
  },
};

export const getters = {};
