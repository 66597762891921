<template>
  <main-menu />
  <router-view />
</template>

<script>
import MainMenu from "./components/MainMenu";
import store from "./store";

export default {
  name: "App.vue",
  components: { MainMenu },
  setup() {
    const playerString = localStorage.getItem("player");
    if (playerString) {
      const responseData = JSON.parse(playerString);
      try {
        store.dispatch("player/automaticLogin", responseData);
      } catch (err) {
        console.log(`Error: App.vue, setup: ${err}`);
      }
    }
  },
};
</script>

<style lang="scss">
@import "./assets/styles/global.scss";
</style>
